import { Button, Menu, Table } from '@mantine/core'
import { useMutation, useQuery } from '@redwoodjs/web'
import { useState } from 'react'
import StyledBadge from 'src/components/DataDisplay/StyledBadge/StyledBadge'
import Empty from 'src/components/Feedback/Empty/Empty'
import StyledMenu from 'src/components/Overlays/StyledMenu/StyledMenu'
import {
  ASSIGN_TRAINING,
  GET_APPLICANT_TRAININGS,
  GET_TRAININGS,
} from 'src/graphql/trainings.graphql'
import IconAdd from 'src/icons/IconAdd'
import { formatSnakeValue } from 'src/lib/formatters'

const TrainingTable = ({ applicant }) => {
  const [opened, setOpened] = useState(false)

  const { data, loading, error } = useQuery(GET_APPLICANT_TRAININGS, {
    variables: {
      applicantId: applicant.applicantId,
    },
  })

  const applicantTrainings = data?.applicantTrainings?.items || []

  const {
    data: availableData,
    loading: availableLoading,
    error: availableError,
  } = useQuery(GET_TRAININGS)

  const availableTrainings = availableData?.trainings?.items || []

  const [assignTraining] = useMutation(ASSIGN_TRAINING, {
    refetchQueries: [
      {
        query: GET_APPLICANT_TRAININGS,
        variables: {
          applicantId: applicant.applicantId,
        },
      },
    ],
  })

  console.log('applicantTrainings', applicantTrainings)
  console.log('availableTrainings', availableTrainings)

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between text-base font-semibold text-doubleNickel-black">
        Current Trainings
        {/* TODO: change this UX  */}
        <StyledMenu opened={opened} onChange={setOpened}>
          <Menu.Target>
            <Button
              color="#667085"
              leftSection={<IconAdd className={'fill-none stroke-white'} />}
              variant="fill"
            >
              Assign Training
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {availableTrainings.map((training) => (
              <Menu.Item
                key={training.trainingId}
                onClick={() => {
                  console.log(
                    'here',
                    applicant.applicantId,
                    training.trainingId
                  )
                  assignTraining({
                    variables: {
                      applicantId: applicant.applicantId,
                      trainingId: training.trainingId,
                    },
                  })
                }}
              >
                {training.name}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </StyledMenu>
      </div>
      <Table
        highlightOnHover
        striped
        verticalSpacing="md"
        classNames={{
          thead: 'bg-doubleNickel-gray-50 text-xs text-doubleNickel-gray-500',
        }}
      >
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Training</Table.Th>
            <Table.Th>Duration</Table.Th>
            <Table.Th>Progress</Table.Th>
          </Table.Tr>
        </Table.Thead>
        {applicantTrainings.length > 0 && (
          <Table.Tbody>
            {applicantTrainings.map((item) => (
              <Table.Tr key={item.trainingId}>
                <Table.Td>{item.training.name}</Table.Td>
                <Table.Td>{item.training.duration}</Table.Td>
                <Table.Td>
                  {<StyledBadge>{formatSnakeValue(item.status)} </StyledBadge>}
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        )}
      </Table>
      {applicantTrainings.length === 0 && (
        <Empty
          title="No Active Trainings"
          subtitle="Assign a training to get started"
        />
      )}
    </div>
  )
}

export default TrainingTable
