import { Divider } from '@mantine/core'
import TrainingTable from './components/TrainingTable'
import TrainingAuditTrail from './components/TrainingAuditTrail'

const TrainingTab = ({ applicant }) => {
  return (
    <div className="flex h-full flex-col gap-6 overflow-auto px-6 pb-20">
      <div className="flex flex-col text-lg font-semibold text-doubleNickel-black">
        {'Training'}
        <div className="text-sm font-medium text-doubleNickel-gray-700">
          This is page is still under contruction. All data seen below is dummy
          data for testing purposes only.
        </div>
      </div>
      <Divider />
      <TrainingTable applicant={applicant} />
      <Divider />
      <TrainingAuditTrail />
    </div>
  )
}

export default TrainingTab
