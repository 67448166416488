import { Menu, ActionIcon, Divider, Tooltip } from '@mantine/core'
import dayjs from 'dayjs'
import { DataTable } from 'mantine-datatable'
import { VerificationOfEmployment } from 'types/graphql'

import StyledBadge from 'src/components/DataDisplay/StyledBadge/StyledBadge'
import { VoEStatus } from 'src/graphql/types/verificationOfEmployments'
import IconChevronDown from 'src/icons/IconChevronDown'
import IconEmptyInbox from 'src/icons/IconEmptyInbox'
import IconPlane from 'src/icons/IconPlane'
import IconThreeDots from 'src/icons/IconThreeDots'
import { calculateAverageJobDuration } from 'src/lib/employment.utils'
import { formatSnakeValue } from 'src/lib/formatters'
import VoEApplicationTableAuditTrailInnerRow from '../VoEApplicationTableAuditTrailInnerRow/VoEApplicationTableAuditTrailInnerRow'
import VoEApplicationTableInnerRow from '../VoEApplicationTableInnerRow/VoEApplicationTableInnerRow'

const colorByStatus: Record<StatusType, 'gray' | 'blue' | 'green' | 'orange'> =
  {
    NOT_STARTED: 'gray',
    REQUESTED: 'blue',
    PENDING_REVIEW: 'orange',
    COMPLETED: 'green',
    IN_PROGRESS: 'blue',
  }

export interface VoEApplicationTableProps {
  voeList?: VerificationOfEmployment[]
  loading?: boolean
  onEdit: (voe) => void
  onUpload: (voe) => void
  onContact: (voe) => void
  onDownload: (documentId) => void
  onPreview: (document) => void
  onEmail: (voe) => void
  onViewDetail: (voe) => void
  onDelete: (voeId) => void
}

const VoEApplicationTable = ({
  loading,
  voeList = [],
  onEdit,
  onUpload,
  onContact,
  onDownload,
  onPreview,
  onEmail,
  onViewDetail,
  onDelete,
}: VoEApplicationTableProps) => {
  return (
    <div className="flex-1">
      <DataTable
        h={11}
        fetching={loading}
        withRowBorders={true}
        emptyState={
          <div className="flex flex-1 flex-col items-center justify-center  ">
            <IconEmptyInbox className="h-40 fill-none" />
            <div className="text-md font-medium text-doubleNickel-gray-800">
              No Records Found
            </div>
            <div className="text-sm text-doubleNickel-gray-600">
              Make sure the application is marked as completed, and the consents
              & employment history are up to date.
            </div>
          </div>
        }
        classNames={{
          header:
            'h-11 bg-doubleNickel-gray-50 text-xs font-medium text-doubleNickel-gray-600',
        }}
        highlightOnHover
        idAccessor="voeId"
        columns={[
          {
            accessor: 'type',
            title: 'Company Name',
            titleClassName: 'px-4',
            cellsClassName: 'px-4 w-[20%]',
            render: (voe) => (
              <div className="flex flex-row items-center gap-3">
                <IconChevronDown className="max-h-3 min-h-3 min-w-3 max-w-3 fill-none stroke-doubleNickel-gray-500" />
                <div>{voe.companyName}</div>
              </div>
            ),
          },
          {
            accessor: 'status',
            title: 'Status',
            cellsClassName: 'w-[15%]',
            render: (voe) => (
              <StyledBadge
                textTransform="capitalize"
                color={colorByStatus[voe.status]}
              >
                {formatSnakeValue(voe.status)}
              </StyledBadge>
            ),
          },
          {
            accessor: 'startDate',
            title: 'Start Date',
            render: (voe) =>
              voe.requestContent.startDate
                ? dayjs(voe.requestContent.startDate).format('MM/YYYY')
                : '-',
          },
          {
            accessor: 'endDate',
            title: 'End Date',
            render: (voe) =>
              voe.requestContent.endDate
                ? dayjs(voe.requestContent.endDate).format('MM/YYYY')
                : 'Present',
          },
          {
            accessor: 'jobDuration',
            title: 'Job Duration',
            render: (voe) => calculateAverageJobDuration([voe.requestContent]),
          },
          {
            accessor: 'shouldContactEmployer',
            title: 'Contact Employer',
            render: (voe) =>
              voe.shouldContactEmployer == undefined ||
              voe.shouldContactEmployer == null
                ? '-'
                : voe.shouldContactEmployer
                ? 'Yes'
                : 'No',
          },
          {
            accessor: 'auditTrailLogs',
            title: 'Contact Attempts',
            render: (voe) =>
              voe.auditTrailLogs.filter(
                (item) =>
                  item.type === 'CALL' ||
                  item.type === 'EMAIL' ||
                  item.type === 'FAX' ||
                  item.type === 'ATS'
              ).length,
          },
          {
            accessor: 'actions',
            title: 'Actions',
            render: (voe) => (
              <div className="flex w-full flex-row items-center justify-center gap-4  ">
                <Tooltip
                  label="Send instructions via email"
                  position="top"
                  withArrow
                >
                  <ActionIcon
                    variant="subtle"
                    className="h-6 w-6"
                    onClick={() => onEmail(voe)}
                  >
                    <IconPlane className="fill-doubleNickel-white stroke-doubleNickel-gray-600" />
                  </ActionIcon>
                </Tooltip>
                <Menu shadow="md" position="bottom-end">
                  <Menu.Target>
                    <ActionIcon
                      variant="subtle"
                      className="h-6 w-6"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <IconThreeDots className="fill-doubleNickel-white stroke-doubleNickel-gray-600" />
                    </ActionIcon>
                  </Menu.Target>
                  <Menu.Dropdown className="w-40">
                    {(voe.status === VoEStatus.PENDING_REVIEW ||
                      voe.status === VoEStatus.COMPLETED) && (
                      <Menu.Item
                        className="text-doubleNickel-gray-700"
                        onClick={() => onViewDetail(voe)}
                      >
                        View
                      </Menu.Item>
                    )}
                    <Menu.Item
                      className="text-doubleNickel-gray-700"
                      onClick={() => onEdit(voe)}
                    >
                      Edit
                    </Menu.Item>
                    <Menu.Item
                      className="text-doubleNickel-gray-700"
                      onClick={() => onUpload(voe)}
                    >
                      Upload
                    </Menu.Item>
                    <Menu.Item
                      className="text-doubleNickel-gray-700"
                      onClick={() => onContact(voe)}
                    >
                      Log Contact Attempt
                    </Menu.Item>
                    {voe.document?.documentId && (
                      <Menu.Item
                        className="text-doubleNickel-gray-700"
                        onClick={() => onPreview(voe.document)}
                      >
                        Preview
                      </Menu.Item>
                    )}
                    {voe.document?.documentId && (
                      <Menu.Item
                        className="text-doubleNickel-gray-700"
                        onClick={() => onDownload(voe?.document?.documentId)}
                      >
                        Download
                      </Menu.Item>
                    )}
                    <Menu.Item
                      className="text-doubleNickel-gray-700"
                      onClick={() => onDelete(voe?.voeId)}
                    >
                      Delete
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </div>
            ),
          },
        ]}
        records={voeList}
        rowExpansion={{
          content: ({ record }: { record: VerificationOfEmployment }) => (
            <div className="flex h-96 flex-row overflow-hidden">
              <VoEApplicationTableInnerRow voe={record} />
              <Divider orientation="vertical" />
              <VoEApplicationTableAuditTrailInnerRow
                auditTrailLogs={record.auditTrailLogs}
              />
            </div>
          ),
        }}
      />
    </div>
  )
}

export default VoEApplicationTable
