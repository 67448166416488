import { Menu, ActionIcon, Box, Indicator, Tooltip } from '@mantine/core'
import dayjs from 'dayjs'
import { EmploymentExperience } from 'types/graphql'

import StyledBadge from 'src/components/DataDisplay/StyledBadge/StyledBadge'
import Empty from 'src/components/Feedback/Empty/Empty'
import StyledAlert from 'src/components/Feedback/StyledAlert/StyledAlert'
import IconThreeDots from 'src/icons/IconThreeDots'
import { calculateAverageJobDuration } from 'src/lib/employment.utils'

import EmploymentDetails from '../../ExploymentDetails'

const DATE_FORMAT = 'MM/YYYY'

const UnemploymentSummaryInfo = ({ item }) => (
  <div className="flex flex-col gap-1 overflow-hidden">
    <div>
      <Indicator
        inline
        position="middle-end"
        color="yellow"
        offset={-14}
        size={6}
      >
        <div className="flex flex-row gap-2 text-sm font-medium text-doubleNickel-gray-900">
          Unemployed
        </div>
      </Indicator>
    </div>
    <Tooltip label={item.unemploymentReason} position="bottom">
      <div className="text-sm text-doubleNickel-gray-900">
        {item.unemploymentReason}
      </div>
    </Tooltip>
  </div>
)

const EmploymentSummaryInfo = ({ item }) => (
  <div className="flex flex-1 flex-col gap-1 overflow-hidden">
    <Tooltip label={item.companyName} position="top">
      <div className="text-sm font-medium text-doubleNickel-gray-900">
        {item.companyName}
      </div>
    </Tooltip>
    <Tooltip label={item.position} position="bottom">
      <div className="text-sm text-doubleNickel-gray-900">{item.position}</div>
    </Tooltip>
  </div>
)

const EmploymentGapInfo = () => (
  <div className="flex flex-col gap-1">
    <div>
      <Indicator inline position="middle-end" color="red" offset={-14} size={6}>
        <div className="text-sm font-medium text-doubleNickel-gray-500">
          Employment Gap
        </div>
      </Indicator>
    </div>
    <div className="text-sm text-doubleNickel-gray-500">
      No job information was reported during this time
    </div>
  </div>
)

const DurationSummaryInfo = ({ item }) => (
  <div className="flex flex-1 flex-col gap-1 overflow-hidden text-end">
    <div className="text-sm text-doubleNickel-gray-700">
      {item?.startDate ? dayjs(item.startDate).format(DATE_FORMAT) : 'N/A'} -{' '}
      {item?.endDate ? dayjs(item.endDate).format(DATE_FORMAT) : 'Current'}{' '}
    </div>
    <div className="text-sm  text-doubleNickel-gray-700">
      Duration: {calculateAverageJobDuration([item])}
    </div>
  </div>
)

type UnregisteredEmploymentSummaryInfoProps = {
  startDate?: string
  endDate?: string
}

const UnregisteredEmploymentSummaryInfo: React.FC<
  UnregisteredEmploymentSummaryInfoProps
> = ({ startDate, endDate }) => (
  <div className="grid grid-cols-2 items-center border-b-[1px] p-4">
    <EmploymentGapInfo />
    <div className="flex flex-col gap-1 text-end">
      <div className="text-sm text-doubleNickel-gray-700">
        {endDate ? dayjs(endDate).format(DATE_FORMAT) : 'Current'} -{' '}
        {startDate ? dayjs(startDate).format(DATE_FORMAT) : 'N/A'}
      </div>
      <div className="text-sm text-doubleNickel-gray-700">
        Duration: {calculateEmploymentGap(startDate, endDate).durationString}
      </div>
    </div>
  </div>
)

const calculateEmploymentGap = (startDate, endDate) => {
  const endDateMonth = dayjs(endDate).endOf('month')

  const duration = dayjs(startDate)
    .diff(dayjs(endDateMonth), 'month', true)
    .toFixed(0)

  const durationNumber = parseInt(duration)
  let durationString = ''

  if (durationNumber >= 12) {
    durationString = (durationNumber / 12).toFixed(1) + ' years'
  } else if (durationNumber < 1) {
    durationString = '< 1 month'
  } else if (durationNumber == 1) {
    durationString = '1 month'
  } else {
    durationString = duration + ' months'
  }

  return { duration: durationNumber, durationString }
}

const checkForSignificantGaps = (experiences) => {
  if (experiences.length === 0) {
    return { startGap: false, endGap: false }
  }

  const currentDate = dayjs().startOf('month')

  // Find the latest experience and calculate the gap
  const latestExperienceEndDate =
    experiences[0].endDate ?? currentDate.toISOString()

  const currentMonthGap = currentDate.diff(
    latestExperienceEndDate,
    'month',
    true
  )

  const earliestEmploymentStartDate = dayjs(
    experiences[experiences.length - 1].startDate
  ).startOf('month')
  const threeYearsAgo = currentDate.subtract(3, 'year')
  const fmcsaThresholdGap = earliestEmploymentStartDate.diff(
    threeYearsAgo,
    'month',
    true
  )

  return {
    startGap: currentMonthGap > 0,
    endGap: fmcsaThresholdGap > 0,
  }
}

const EmploymentTable = ({
  experiences,
  handleDelete,
  setCurrentIndex,
  currentIndex,
  handleEdit,
}: {
  experiences: EmploymentExperience[]
  handleDelete: (index: string) => void
  currentIndex: number
  setCurrentIndex: any
  handleEdit: any
}) => {
  const { startGap, endGap } = checkForSignificantGaps(experiences)
  const endDateMonth = startGap
    ? dayjs(experiences[0].endDate).add(1, 'month').endOf('month').toISOString()
    : null
  const threeYearsAgo = dayjs().subtract(3, 'year')
  const threeYearsAgoLatest = endGap
    ? dayjs(experiences[experiences.length - 1].startDate)
        .subtract(1, 'month')
        .endOf('month')
        .toISOString()
    : null

  return (
    <div className="flex flex-row">
      <div className="flex flex-1 flex-col overflow-hidden border-r-[1px]">
        <div className="mb-4 text-sm font-semibold text-doubleNickel-gray-700">
          Experiences
        </div>

        {experiences.length > 0 ? (
          <>
            {startGap && (
              <UnregisteredEmploymentSummaryInfo
                startDate={new Date().toISOString()}
                endDate={endDateMonth}
              />
            )}
            {experiences.map((item, index) => (
              <div key={index}>
                <Box
                  className="flex flex-row items-center gap-2"
                  onClick={() => {
                    setCurrentIndex(index)
                  }}
                >
                  <div
                    className={`grid flex-1 grid-cols-2 items-center justify-between gap-5 border-b-[1px] p-4 hover:bg-doubleNickel-brand-25
                ${
                  index === currentIndex
                    ? 'border-l-4 border-l-doubleNickel-brand-500 bg-doubleNickel-brand-25'
                    : 'transparent'
                }`}
                  >
                    {item.isUnemployment ? (
                      <UnemploymentSummaryInfo item={item} />
                    ) : (
                      <EmploymentSummaryInfo item={item} />
                    )}
                    <DurationSummaryInfo item={item} />
                  </div>
                </Box>
                {experiences.length > index + 1 &&
                  calculateEmploymentGap(
                    experiences[index].startDate,
                    experiences[index + 1].endDate
                  ).duration > 0 && (
                    <UnregisteredEmploymentSummaryInfo
                      startDate={experiences[index].startDate}
                      endDate={experiences[index + 1].endDate}
                    />
                  )}
              </div>
            ))}
            {endGap && (
              <UnregisteredEmploymentSummaryInfo
                startDate={threeYearsAgoLatest}
                endDate={threeYearsAgo.toString()}
              />
            )}
          </>
        ) : (
          <StyledAlert
            title={`No jobs reported`}
            description="Add an employment experience to get started"
          />
        )}
      </div>

      <div className="flex flex-1 flex-col pb-3 pl-4 ">
        <div className="flex flex-row items-center justify-between text-sm font-semibold text-doubleNickel-gray-700">
          Experience details
          {experiences[currentIndex] && (
            <Menu shadow="md" position="bottom-end">
              <Menu.Target>
                <ActionIcon
                  variant="subtle"
                  onClick={(e) => e.stopPropagation()}
                >
                  <IconThreeDots className="h-4 fill-doubleNickel-white stroke-doubleNickel-gray-600" />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown className="w-40">
                <Menu.Item
                  className="text-doubleNickel-gray-700"
                  onClick={() => {
                    handleEdit()
                  }}
                >
                  Edit
                </Menu.Item>
                <Menu.Item
                  className="text-doubleNickel-gray-700"
                  onClick={() => {
                    const item = experiences[currentIndex]
                    setCurrentIndex(null)
                    handleDelete(item.employmentExperienceId)
                  }}
                >
                  Delete
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          )}
        </div>

        {experiences[currentIndex] ? (
          <EmploymentDetails employmentExperience={experiences[currentIndex]} />
        ) : (
          <Empty
            title="No job selected"
            subtitle="Select an employment experience to view details"
          />
        )}
      </div>
    </div>
  )
}

export default EmploymentTable
