export const GET_TRAININGS = gql`
  query getTrainings {
    trainings {
      items {
        trainingId
        name
        duration
        provider
        createdAt
        updatedAt
        trainingType
        parentTrainingId
        parentTraining {
          name
          duration
          provider
          trainingId
          trainingType
        }
        trainings {
          name
          duration
          provider
          trainingId
          trainingType
        }
      }
      totalCount
    }
  }
`

export const GET_TRAINING = gql`
  query getTraining($trainingId: String!) {
    training(trainingId: $trainingId) {
      trainingId
      name
      duration
      provider
      createdAt
      updatedAt
    }
  }
`

export const GET_APPLICANT_TRAININGS = gql`
  query getApplicantTrainings($applicantId: String!) {
    applicantTrainings(applicantId: $applicantId) {
      items {
        applicantTrainingId
        trainingId
        training {
          trainingId
          name
          duration
          provider
          createdAt
          updatedAt
        }
        applicantId
        applicant {
          applicantId
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        status
        startDate
        endDate
        deadline
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`

export const ASSIGN_TRAINING = gql`
  mutation assignTraining($applicantId: String!, $trainingId: String!) {
    assignTraining(applicantId: $applicantId, trainingId: $trainingId) {
      applicantTrainingId
    }
  }
`
