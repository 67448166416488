import { MultiSelect, MultiSelectProps } from '@mantine/core'

interface SelectInputProps extends MultiSelectProps {
  editing: boolean
  value: string[]
  data: any[]
  handleChange?: any
}

const MultiSelectInput = ({
  editing,
  value,
  data,
  handleChange,
  ...props
}: SelectInputProps) => {
  return (
    <MultiSelect
      {...props}
      disabled={!editing}
      classNames={{
        label: 'text-sm font-medium text-doubleNickel-gray-700',
        wrapper:
          '[&[data-disabled]]:opacity-100 [&[data-disabled]]:bg-white bg-white',
        pillsList: 'bg-white',
        section: 'bg-white',
      }}
      variant={editing ? 'default' : 'unstyled'}
      value={value ?? []}
      data={data}
      onChange={(value) => handleChange(value)}
    />
  )
}

export default MultiSelectInput
