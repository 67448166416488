import { ActionIcon, Checkbox, Collapse } from '@mantine/core'
import { useState } from 'react'

import Button from 'src/components/Buttons/Button/Button'
import IconChevronDown from 'src/icons/IconChevronDown'

const ConsentCard = ({ consents = [], onPreview }) => {
  const [opened, setOpened] = useState(
    consents.filter((consent) => consent.document).length > 0
  )

  return (
    <div
      className={`flex w-full flex-col gap-4 rounded-lg border border-doubleNickel-gray-200 p-3`}
    >
      <div
        className="text-md flex w-full flex-row items-center gap-4 font-semibold text-doubleNickel-black"
        onClick={() => setOpened((prev) => !prev)}
      >
        <ActionIcon variant="subtle">
          <IconChevronDown
            className={`h-4 w-4 ${
              !opened && '-rotate-90'
            } fill-none stroke-doubleNickel-gray-500 transition-all`}
          />
        </ActionIcon>
        Consents
      </div>
      <Collapse in={opened} transitionDuration={400}>
        <div className="flex flex-col px-4">
          {consents
            .filter((consent) => consent.document)
            .map((consent) => (
              <div
                key={consent.consentId}
                className="flex flex-row items-center gap-3"
              >
                <Checkbox checked={consent.confirmed} disabled />
                <div className="text-sm font-medium text-doubleNickel-gray-700">
                  {consent.title}
                </div>
                <Button
                  onClick={() => onPreview(consent)}
                  variant="subtle"
                  text="Review Form"
                  className="ml-auto"
                />
              </div>
            ))}
        </div>
      </Collapse>
    </div>
  )
}

export default ConsentCard
