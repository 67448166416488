import { useEffect } from 'react'

import { Modal, TextInput, Select, Group } from '@mantine/core'
import { useForm } from '@mantine/form'
import dayjs from 'dayjs'
import { zodResolver } from 'mantine-form-zod-resolver'
import { z } from 'zod'

import { useMutation } from '@redwoodjs/web'

import Button from 'src/components/Buttons/Button/Button'
import DateInput from 'src/components/Inputs/DateInput/DateInput'
import { toast } from 'src/components/Overlays/Toast/Toast'
import { VoEAuditTrailType } from 'src/graphql/types/verificationOfEmployments'
import { CREATE_VOE_AUDIT_TRAIL } from 'src/graphql/voe.graphql'
import { formatSnakeValue } from 'src/lib/formatters'
import PhoneInput from 'src/components/Inputs/PhoneInput/PhoneInput'

import { removeCountryCode, unformatPhoneNumber } from 'src/lib/phone.utils'

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}

const ContactModal = ({ voe, isOpen, onRender, onClose }) => {
  //This should reflect the VoEAuditTrailType enum in the api
  const contactTypeData = Object.values(VoEAuditTrailType).map((type) => ({
    label: type === 'ATS' ? 'Other (e.g. ATS)' : formatSnakeValue(type),
    value: type,
  }))

  const schema = z.object({
    contactMethod: z.string({
      invalid_type_error: 'Contact method is required',
      required_error: 'Contact method is required',
    }),
    contactDate: z.date(),
    email: z.string().email({ message: 'Invalid email' }).optional(),
    phone: z
      .string()
      .refine(
        (data) =>
          unformatPhoneNumber(removeCountryCode(data)).length === 10 ||
          unformatPhoneNumber(removeCountryCode(data)).length === 0,
        {
          message: 'Phone number should have 10 digits',
        }
      )
      .optional(),
    fax: z
      .string()
      .refine(
        (data) =>
          unformatPhoneNumber(removeCountryCode(data)).length === 10 ||
          unformatPhoneNumber(removeCountryCode(data)).length === 0,
        {
          message: 'Fax number should have 10 digits',
        }
      )
      .optional(),
    message: z.string().optional(),
  })

  const form = useForm({
    initialValues: {
      contactMethod: undefined,
      contactDate: new Date(),
      email: undefined,
      phone: undefined,
      fax: undefined,
      message: undefined,
    },
    validate: zodResolver(schema),
  })

  const [createVoEAuditTrail] = useMutation(CREATE_VOE_AUDIT_TRAIL, {
    refetchQueries: onRender,
    onCompleted: () => {
      toast('Contact attempt saved successfully', 'success')
      onClose()
    },
    onError,
  })

  const resetValues = () => {
    form.setValues({
      contactMethod: undefined,
      contactDate: new Date(),
      email: undefined,
      phone: undefined,
      fax: undefined,
      message: undefined,
    })
  }

  //Reset values each time the modal is opened
  useEffect(() => {
    resetValues()
  }, [isOpen])

  const handleSubmit = (values) => {
    createVoEAuditTrail({
      variables: {
        voeId: voe.voeId,
        input: {
          title: `${formatSnakeValue(values.contactMethod)} Attempt`,
          message:
            values.message !== undefined && values.message !== ''
              ? values.message
              : `Contact attempt made via ${formatSnakeValue(
                  values.contactMethod
                )} on ${dayjs(values.contactDate).format(
                  'MM/DD/YYYY [at] h:mm A'
                )}`,
          type: form.values.contactMethod,
          contactDate: values.contactDate,
          ...(values.email !== undefined && { email: values.email }),
          ...(values.fax !== undefined && { fax: values.fax }),
          ...(values.phone !== undefined && { phone: values.phone }),
        },
      },
    })
  }

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      classNames={{ title: 'text-lg font-bold' }}
      title="Log contact attempt"
      centered
    >
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <div className="flex flex-col gap-6">
          <Select
            withAsterisk
            label="Contact Method"
            placeholder="Select contact method"
            data={contactTypeData}
            value={form.values.contactMethod as string}
            error={form.errors.contactMethod}
            onChange={(value) => {
              resetValues()
              form.setFieldValue('contactMethod', value)
            }}
            required
          />
          {form.values.contactMethod === VoEAuditTrailType.EMAIL && (
            <TextInput
              placeholder={'Email'}
              required
              label="Email"
              {...form.getInputProps('email')}
            />
          )}
          {form.values.contactMethod === VoEAuditTrailType.CALL && (
            <PhoneInput
              required
              label="Phone Number"
              value={form.values.phone as string}
              onChange={(value) => form.setFieldValue('phone', value)}
              error={form.errors.phone}
            />
          )}
          {form.values.contactMethod === VoEAuditTrailType.FAX && (
            <PhoneInput
              required
              label="Fax Number"
              value={form.values.fax as string}
              onChange={(value) => form.setFieldValue('fax', value)}
              error={form.errors.fax}
            />
          )}
          <DateInput
            label="Date of Contact"
            editing={true}
            required
            value={form.values.contactDate as Date}
            onChange={(value) => {
              form.setFieldValue(`contactDate`, value)
            }}
            error={form.errors.contactDate}
          />

          <TextInput
            label={'Notes'}
            placeholder="Add a note about the contact attempt"
            {...form.getInputProps('message')}
          />
        </div>

        <Group
          justify="flex-end"
          mt="md"
          className="grid grid-cols-2 gap-4 border-t py-4"
        >
          <Button text="Cancel" variant="outline" onClick={onClose} />
          <Button text="Save" variant="filled" type="submit" />
        </Group>
      </form>
    </Modal>
  )
}

export default ContactModal
