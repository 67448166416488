import { Box } from '@mantine/core'

import Button from 'src/components/Buttons/Button/Button'
import IconCheckmark from 'src/icons/IconCheckmark'

export const ApplicantSharingModalFeedback = ({
  title,
  subTitle,
  onNavigateToApplicant,
}) => {
  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-8">
      <Box className="flex h-24 w-24 flex-col items-center justify-center rounded-full bg-doubleNickel-brand-25">
        <IconCheckmark className="h-14 w-14 fill-none stroke-doubleNickel-brand-500" />
      </Box>
      <div>
        <div className="text-md text-center font-semibold text-doubleNickel-gray-800 sm:text-xl sm:font-bold">
          {title}
        </div>
        <div className="sm:text-md text-center text-sm text-doubleNickel-gray-700">
          {subTitle}
        </div>
      </div>

      <Button
        text="View new application"
        className="bg-doubleNickel-brand-500"
        onClick={onNavigateToApplicant}
      />
    </div>
  )
}
