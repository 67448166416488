import { Text, Timeline } from '@mantine/core'
import dayjs from 'dayjs'
import IconEdit from 'src/icons/IconEdit'

import { formatInternationalPhoneNumber } from 'src/lib/phone.utils'

// Helper function to get the info based on the audit trail type
const getInfoBasedOnType = (auditTrailLog) => {
  console.log(auditTrailLog)
  switch (auditTrailLog.type) {
    case 'CALL':
      return formatInternationalPhoneNumber(auditTrailLog.phone)
    case 'EMAIL':
      return auditTrailLog.email
    case 'FAX':
      return formatInternationalPhoneNumber(auditTrailLog.fax)
    default:
      return '-'
  }
}

const VoEApplicationTableAuditTrailInnerRow = ({ auditTrailLogs = [] }) => {
  return (
    <div className="flex h-full flex-1 flex-col gap-3 overflow-scroll p-6">
      <div className="flex flex-row items-center justify-between">
        <div className="font-semibold text-doubleNickel-gray-600">
          Activity Log
        </div>
        <div className="font-semibold text-doubleNickel-gray-600">
          Contact Attempts:{' '}
          {
            auditTrailLogs.filter(
              (item) =>
                item.type === 'CALL' ||
                item.type === 'EMAIL' ||
                item.type === 'FAX' ||
                item.type === 'ATS'
            ).length
          }
        </div>
      </div>
      <Timeline
        active={auditTrailLogs.length - 1}
        bulletSize={15}
        lineWidth={2}
      >
        {auditTrailLogs.map((auditTrailLog) => (
          <Timeline.Item title={auditTrailLog.title}>
            <Text c="dimmed" size="sm">
              <Text variant="link" component="span" inherit>
                {auditTrailLog.message} {getInfoBasedOnType(auditTrailLog)}
              </Text>
            </Text>
            <Text size="xs" mt={4}>
              {dayjs(auditTrailLog.createdAt).format('hh:mm A MM/DD/YYYY')}
            </Text>
          </Timeline.Item>
        ))}

        {auditTrailLogs.length === 0 && (
          <Timeline.Item title="Activity Log Created">
            <Text c="dimmed" size="sm">
              <Text variant="link" component="span" inherit>
                Log a contact attempt to get started
              </Text>
            </Text>
          </Timeline.Item>
        )}
      </Timeline>
    </div>
  )
}

export default VoEApplicationTableAuditTrailInnerRow
