import { useState } from 'react'
import _ from 'lodash'
import TextInput from '../TextInput/TextInput'
import { EquipmentClass } from 'src/graphql/types/drivingExperiences'
import { formatSnakeValue } from 'src/lib/formatters'
import SelectInput from '../SelectInput/SelectInput'
import ApplicationCard from '../ApplicationCard/ApplicationCard'

const EquipmentCard = ({ values: appValues = [], handleUpdateApplication }) => {
  const [values, setValues] = useState({ ...appValues[0] })
  const [editing, setEditing] = useState(false)
  const isEmpty = _.isEmpty(values)

  const equipmentTypes = Object.values(EquipmentClass).map((value) => ({
    value: value,
    label: formatSnakeValue(value),
  }))

  const handleCancel = () => {
    setValues({ ...appValues[0] })
    setEditing(false)
  }

  const handleSave = () => {
    const parsedValues = { ...values }
    parsedValues.year = parseInt(parsedValues.year)
    parsedValues.weight = parseFloat(parsedValues.weight)
    parsedValues.mileage = parseFloat(parsedValues.mileage)
    parsedValues.fifthWheelHeightInches = parseFloat(
      parsedValues.fifthWheelHeightInches
    )
    let updates = { equipment: [] }
    updates.equipment.push(parsedValues)
    handleUpdateApplication(updates).then((res) => {
      if (!res.errors) {
        setEditing(false)
      }
    })
  }

  return (
    <ApplicationCard
      title={'Owner Operator Equipment'}
      editing={editing}
      canAdd={false}
      showAlert={false}
      isEmpty={isEmpty}
      setEditing={setEditing}
      handleSave={handleSave}
      handleCancel={handleCancel}
    >
      <div className={`grid grid-cols-4 gap-4`}>
        <SelectInput
          label="Equipment Type"
          editing={editing}
          value={values.type}
          data={equipmentTypes}
          handleChange={(value) => {
            setValues({ ...values, type: value })
          }}
        />
        <TextInput
          label="Year"
          type="number"
          inputMode="numeric"
          editing={editing}
          value={values.year}
          handleChange={(value) => setValues({ ...values, year: value })}
        />
        <TextInput
          label="Make"
          editing={editing}
          value={values.make}
          handleChange={(value) => setValues({ ...values, make: value })}
        />
        <TextInput
          label="Model"
          editing={editing}
          value={values.model}
          handleChange={(value) => setValues({ ...values, model: value })}
        />
        <TextInput
          label="VIN"
          editing={editing}
          value={values.vin}
          handleChange={(value) => setValues({ ...values, vin: value })}
        />
        <TextInput
          label="Weight"
          type="number"
          inputMode="decimal"
          editing={editing}
          value={values.weight}
          handleChange={(value) => setValues({ ...values, weight: value })}
        />
        <TextInput
          label="Mileage"
          type="number"
          inputMode="decimal"
          editing={editing}
          value={values.mileage}
          handleChange={(value) => setValues({ ...values, mileage: value })}
        />
        <TextInput
          label="Fifth Wheel Height (inches)"
          type="number"
          inputMode="decimal"
          editing={editing}
          value={values.fifthWheelHeightInches}
          handleChange={(value) =>
            setValues({ ...values, fifthWheelHeightInches: value })
          }
        />
      </div>
    </ApplicationCard>
  )
}

export default EquipmentCard
