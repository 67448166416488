import StyledReadOnlyInput from 'src/components/Inputs/StyledReadOnlyInput/StyledReadOnlyInput'

import { formatExperience } from 'src/lib/formatters'

export const calculateSummary = (employmentExperiences) => {
  let totalMonthsExperience = 0
  let longestTenureMonths = 0
  let totalGapMonths = 0
  let previousStartDate = null

  // Sort the array by date in descending order
  const sortedExperiences = [...employmentExperiences]
  sortedExperiences.sort((a, b) => {
    const dateA = new Date(a.startDate).getTime()
    const dateB = new Date(b.startDate).getTime()
    return dateB - dateA
  })

  sortedExperiences
    .sort((a, b) => b.startDate - a.startDate)
    .forEach((job, index) => {
      const start = new Date(job.startDate)
      const end = job.endDate ? new Date(job.endDate) : new Date() // Use current date if endDate is null
      const durationMonths =
        (end.getFullYear() - start.getFullYear()) * 12 +
        end.getMonth() -
        start.getMonth()

      totalMonthsExperience += durationMonths

      if (durationMonths > longestTenureMonths) {
        longestTenureMonths = durationMonths
      }

      if (previousStartDate && index > 0) {
        const gapMonths =
          (previousStartDate.getFullYear() - end.getFullYear()) * 12 +
          previousStartDate.getMonth() -
          end.getMonth()
        totalGapMonths += gapMonths
      }

      previousStartDate = start
    })

  const averageDurationMonths =
    employmentExperiences.length > 0
      ? totalMonthsExperience / employmentExperiences.length
      : 0

  const avergageGapMonths =
    employmentExperiences.length > 1
      ? totalGapMonths / (employmentExperiences.length - 1)
      : 0

  const convertMonthsToYears = (months) => months / 12

  return {
    yearsOfExperience: convertMonthsToYears(totalMonthsExperience),
    numberOfJobs: employmentExperiences.length,
    averageDuration: convertMonthsToYears(averageDurationMonths),
    longestTenure: convertMonthsToYears(longestTenureMonths),
    timeGap: convertMonthsToYears(avergageGapMonths),
  }
}

const EmploymentSummaryCard = ({ application }) => {
  const displayExperience = (value) => {
    return value === 0 ? '-' : formatExperience(value)
  }

  const summary = calculateSummary(
    application.employmentExperiences.filter((job) => !job.isUnemployment)
  )

  return (
    <div
      className={`flex w-full flex-col gap-4 rounded-lg border border-doubleNickel-gray-200 p-4`}
    >
      <div className=" text-md flex flex-row items-center justify-between font-semibold text-doubleNickel-black">
        Employment Summary
      </div>
      <div className="grid grid-cols-5 gap-2">
        <StyledReadOnlyInput
          label="Years of Experience"
          value={displayExperience(summary.yearsOfExperience)}
        />
        <StyledReadOnlyInput
          label="Number of Jobs"
          value={summary.numberOfJobs}
        />
        <StyledReadOnlyInput
          label="Avg Duration"
          value={displayExperience(summary.averageDuration)}
        />
        <StyledReadOnlyInput
          label="Longest Tenure"
          value={displayExperience(summary.longestTenure)}
        />
        <StyledReadOnlyInput
          label="Avg Time between Jobs"
          value={displayExperience(summary.timeGap)}
        />
      </div>
    </div>
  )
}

export default EmploymentSummaryCard
