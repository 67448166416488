import { Modal, Select, TextInput, Group } from '@mantine/core'
import { useForm } from '@mantine/form'

import { useMutation } from '@redwoodjs/web'

import Button from 'src/components/Buttons/Button/Button'
import DateInput from 'src/components/Inputs/DateInput/DateInput'
import { toast } from 'src/components/Overlays/Toast/Toast'
import { UPDATE_DOCUMENT } from 'src/graphql/documents.graphql'
import {
  DocumentCategory,
  DocumentUserStatus,
} from 'src/graphql/types/documents'
import { formatSnakeValue } from 'src/lib/formatters'

const categories = Object.values(DocumentCategory).map((category) => ({
  label: formatSnakeValue(category),
  value: category,
}))
const statuses = Object.values(DocumentUserStatus)

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}

const EditDocumentModal = ({ document, isOpen, onRender, onClose }) => {
  const form = useForm({
    initialValues: {
      category: document.category,
      userStatus: document.userStatus,
      fileName: document.fileName,
      expirationDate: document.expirationDate
        ? new Date(document.expirationDate)
        : null,
    },
  })

  const [updateDocument] = useMutation(UPDATE_DOCUMENT, {
    refetchQueries: onRender,
    onCompleted: () => {
      toast('Document updated successfully', 'success')
      onClose()
    },
    onError,
  })

  const onFormSubmit = (values) => {
    updateDocument({
      variables: {
        id: document.documentId,
        input: {
          expirationDate: values.expirationDate
            ? new Date(values.expirationDate)
            : null,
          fileName: values.fileName,
          userStatus: values.userStatus,
          category: values.category,
        },
      },
    })
  }

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      classNames={{ title: 'text-lg font-bold' }}
      title="Edit document"
      centered
    >
      <div className="flex flex-col gap-6">
        <form onSubmit={form.onSubmit((values) => onFormSubmit(values))}>
          <div className="flex-row flex-col">
            <div className="grid grid-cols-2 gap-4">
              <TextInput
                required
                className="col-span-2"
                placeholder={
                  form.getInputProps('file').value
                    ? form.getInputProps('file').value.name
                    : 'Enter a file name'
                }
                label="File name"
                {...form.getInputProps('fileName')}
              />
              <Select
                withAsterisk
                label="Category"
                placeholder="Select category"
                data={categories}
                searchable
                required
                {...form.getInputProps('category')}
              />

              <Select
                withAsterisk
                label="Status"
                placeholder="Select status"
                data={statuses.map((status) => ({
                  label: formatSnakeValue(status),
                  value: status,
                }))}
                required
                {...form.getInputProps('userStatus')}
              />
              <DateInput
                label="Expiration Date"
                clearable
                value={form.values.expirationDate}
                onChange={(date) => form.setFieldValue('expirationDate', date)}
              />
            </div>
            <Group
              justify="flex-end"
              mt="md"
              className="grid grid-cols-2 gap-4 border-t py-4"
            >
              <Button text="Cancel" variant="outline" onClick={onClose} />
              <Button
                type="submit"
                disabled={!form.isValid()}
                text="Save"
                variant="filled"
              />
            </Group>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default EditDocumentModal
