import { useState } from 'react'

import { ActionIcon, Loader, Tooltip } from '@mantine/core'

import { useQuery } from '@redwoodjs/web'

import Button from 'src/components/Buttons/Button/Button'
import DateInput from 'src/components/Inputs/DateInput/DateInput'
import { toast } from 'src/components/Overlays/Toast/Toast'
import { GET_JOB_LISTINGS } from 'src/graphql/joblistings.graphql'
import IconEdit from 'src/icons/IconEdit'
import { formatSnakeValue } from 'src/lib/formatters'

import SelectInput from '../SelectInput/SelectInput'

const JobInformationCard = ({
  values: appValues = [],
  handleUpdateApplication,
}) => {
  const [values, setValues] = useState({ ...appValues[0] })
  const [editing, setEditing] = useState(false)

  const { data: jobListingsResponse, loading: jobListingsLoading } = useQuery(
    GET_JOB_LISTINGS,
    {
      variables: {
        pageInfo: { offset: 0, limit: 50 },
      },
      onError: () => {
        toast('Unable to fetch job listings, please try again later.', 'error')
      },
    }
  )

  const jobListingOptions =
    jobListingsResponse?.jobListings.items?.map((jl) => ({
      value: jl.jobListingId,
      label: jl.title,
    })) || []

  const calculateTrackingLinks = (jobListingId) => {
    const jobListing = jobListingsResponse?.jobListings?.items.find(
      (jl) => jl.jobListingId === jobListingId
    )
    return jobListing?.trackingLinks.map((link) => ({
      value: link.trackingLinkId,
      label: `${formatSnakeValue(link.linkType)}${
        link?.linkName ? `: ${link.linkName}` : ``
      }`,
    }))
  }

  const handleCancel = () => {
    setValues({ ...appValues[0] })
    setEditing(false)
  }

  const isSaveDisabled = () => {
    return !values.jobListingId || !values.trackingLinkId
  }

  const handleSave = () => {
    let updates = {}
    updates = {
      ...(values.availableForWork !== undefined && {
        availableForWork: values.availableForWork,
      }),
      ...(values.jobListingId !== undefined && {
        jobListingId: values.jobListingId,
      }),
      ...(values.trackingLinkId !== undefined && {
        trackingLinkId: values.trackingLinkId,
      }),
    }
    handleUpdateApplication(updates).then((res) => {
      if (!res.errors) {
        setEditing(false)
      }
    })
  }

  return (
    <div
      className={`flex w-full flex-col gap-4 rounded-lg border border-doubleNickel-gray-200 p-4`}
    >
      <div className=" text-md flex flex-row items-center justify-between font-semibold text-doubleNickel-black">
        Job Information
        {!editing && (
          <ActionIcon
            className="h-8 w-8 border border-doubleNickel-gray-200 bg-doubleNickel-white shadow-sm hover:bg-doubleNickel-gray-200"
            onClick={() => setEditing(true)}
          >
            <IconEdit className="h-4 w-4 fill-none stroke-doubleNickel-gray-500" />
          </ActionIcon>
        )}
      </div>
      <div className={`grid grid-cols-4 gap-3`}>
        <SelectInput
          label="Job Position"
          required={editing}
          editing={editing}
          value={values.jobListingId}
          rightSection={
            editing && jobListingsLoading ? <Loader size="1rem" /> : null
          }
          data={jobListingOptions}
          handleChange={(value) => {
            setValues({ ...values, jobListingId: value, trackingLinkId: null })
          }}
        />
        <SelectInput
          label="Tracking Link"
          required={editing}
          editing={editing}
          value={values.trackingLinkId}
          data={calculateTrackingLinks(values.jobListingId)}
          handleChange={(value) => {
            setValues({ ...values, trackingLinkId: value })
          }}
        />
        <DateInput
          label={'Available For Work'}
          editing={editing}
          value={values.availableForWork}
          minDate={new Date()}
          onChange={(value) => {
            setValues({ ...values, availableForWork: value })
          }}
        />
        <DateInput
          label={'Application Date'}
          editing={false}
          value={values.applicationDate}
        />
      </div>
      {editing && (
        <div className="flex flex-row gap-4">
          <Button
            text="Cancel"
            variant="outline"
            className="ml-auto"
            onClick={handleCancel}
          />
          <Tooltip
            label="Job position and job source are required"
            disabled={!isSaveDisabled()}
          >
            <Button
              text="Save"
              onClick={handleSave}
              disabled={isSaveDisabled()}
            />
          </Tooltip>
        </div>
      )}
    </div>
  )
}

export default JobInformationCard
