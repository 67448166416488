import { useMemo } from 'react'

import { Box, Divider, Menu, Tooltip } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import dayjs from 'dayjs'
import type { Step, StepsResult } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import Button from 'src/components/Buttons/Button/Button'
import StyledBadge from 'src/components/DataDisplay/StyledBadge/StyledBadge'
import { toast } from 'src/components/Overlays/Toast/Toast'
import { UPDATE_STEP } from 'src/graphql/steps.graphql'
import { DocumentUserStatus } from 'src/graphql/types/documents'
import { StepType, StepStatus } from 'src/graphql/types/steps'
import IconAdd from 'src/icons/IconAdd'
import IconAlertTriangle from 'src/icons/IconAlertTriangle'
import IconChevronDown from 'src/icons/IconChevronDown'
import IconEmptyInbox from 'src/icons/IconEmptyInbox'
import IconRightArrow from 'src/icons/IconRightArrow'
import IconUploadSimple from 'src/icons/IconUploadSimple'
import { willDateExpireSoon } from 'src/lib/dates.util'
import { formatSnakeValue, formatStepType } from 'src/lib/formatters'

import DQFDocument from './components/DQFDocument'
import RequestOrderModal from './components/RequestOrderModal'

const colorByStatus = {
  NOT_STARTED: 'gray',
  IN_PROGRESS: 'blue',
  PENDING_REVIEW: 'orange',
  COMPLETED: 'green',
  EXPIRED: 'red',
}

interface DQFContentProps {
  steps: StepsResult
  documentsResponse: any
  onEdit: (document: Document, step: Step) => void
  onPreview: (document: any) => void
  onDelete: (documentId: string, step: Step) => void
  onRenewed: (documentId: string, isRenewed: boolean) => void
  onDownload: (document: any) => void
  handleUpload: (selected: any) => void
  onStepChange: (step: Step) => void
  refetchQueries: any
  onError: (error: any) => void
  applicantId: string
  selected: Step
  setSelected: (selected: Step) => void
}

const DQFContent = ({
  steps,
  documentsResponse,
  onEdit,
  onPreview,
  onDelete,
  onRenewed,
  onDownload,
  onStepChange,
  handleUpload,
  refetchQueries,
  onError,
  applicantId,
  selected,
  setSelected,
}: DQFContentProps) => {
  const [
    requestOrderModalOpened,
    { open: openRequestOrderModal, close: closeRequestOrderModal },
  ] = useDisclosure(false)

  const DOT_TEXT = `It is suggested that the ${formatStepType(
    StepType.DOT_APP
  )} be completed prior to requesting.`
  const isDotComplete = useMemo(() => {
    return !!steps.items.find(
      (step) =>
        step.type === StepType.DOT_APP && step.status === StepStatus.COMPLETED
    )
  }, [steps])

  const handleStepSelection = (step) => {
    setSelected(step)
    onStepChange(step)
  }

  const statuses = Object.values(StepStatus)

  const [updateStep] = useMutation(UPDATE_STEP, {
    refetchQueries,
    onCompleted: () => {
      toast('DQF updated successfully', 'success')
    },
    onError,
  })

  const [expiredDocuments, currentDocuments, renewedDocuments] = useMemo(() => {
    const expired = []
    const current = []
    const renewed = []

    documentsResponse?.forEach((doc) => {
      if (doc.isRenewed) {
        renewed.push(doc)
      } else if (
        !doc.expirationDate || // Treat documents without an expiration date as current
        dayjs().isBefore(dayjs(doc.expirationDate).endOf('day'))
      ) {
        current.push(doc)
      } else {
        expired.push(doc)
      }
    })

    return [expired, current, renewed]
  }, [documentsResponse])

  return (
    <div className=" grid h-[90%] grid-cols-2 gap-4 ">
      <div className="flex flex-col gap-3 overflow-auto ">
        <Box className="font-lg flex flex-row items-center justify-between font-bold text-doubleNickel-gray-700">
          Items
          <Tooltip
            label={DOT_TEXT}
            withArrow
            disabled={isDotComplete}
            className="text-xs"
          >
            <Button
              text="Request"
              lefticon={<IconAdd />}
              onClick={openRequestOrderModal}
              // disabled={!isDotComplete}
            />
          </Tooltip>
        </Box>

        <div className="flex flex-col gap-8">
          <div className="flex flex-col">
            {steps.items.map((step) => {
              const actionDisabled =
                step.type !== StepType.DOT_APP && !isDotComplete
              const activeDocuments = step.documents.filter(
                (doc) => doc.status != 'ARCHIVED' && !doc.isRenewed
              )
              // Find the soonest expiration date
              const nearestExpiration = activeDocuments.reduce((acc, doc) => {
                if (doc.expirationDate === null) return acc
                if (acc === null) return doc.expirationDate
                return new Date(doc.expirationDate) < new Date(acc)
                  ? doc.expirationDate
                  : acc
              }, null)

              //Check if any document is expired
              const today = dayjs()
              const anyExpiredDocument =
                activeDocuments.length > 0
                  ? activeDocuments.some((doc) => {
                      if (doc.expirationDate === null) return false
                      const expirationDate = dayjs(
                        new Date(doc.expirationDate)
                      ).endOf('day')
                      const daysUntilDueDate = expirationDate.diff(today, 'day')
                      return daysUntilDueDate < 0
                    })
                  : false
              //Check if the date is expiring in the next 30 days
              const { isDateExpiring, daysUntilDueDate } = willDateExpireSoon(
                nearestExpiration,
                30
              )

              let expirationLabel = ''

              if (nearestExpiration) {
                const expirationDate = dayjs(nearestExpiration).startOf('day')
                const daysUntilExpiration = expirationDate.diff(today, 'day')

                if (daysUntilExpiration === 0) {
                  expirationLabel = 'Expires today'
                } else if (daysUntilExpiration === 1) {
                  expirationLabel = 'Expires in 1 day'
                } else {
                  expirationLabel = `Expires in ${daysUntilExpiration} days`
                }
              }

              return (
                <Box
                  key={step.stepId}
                  onClick={() => handleStepSelection(step)}
                  className={`flex cursor-pointer flex-row justify-between border-b px-2 py-3 text-sm hover:text-doubleNickel-brand-500
                    ${
                      selected.type === step.type
                        ? 'rounded-lg bg-doubleNickel-brand-25 font-semibold text-doubleNickel-brand-500'
                        : 'font-medium text-doubleNickel-gray-600'
                    }`}
                >
                  <div className="flex flex-row gap-4">
                    {formatStepType(step.type)}

                    {isDateExpiring && (
                      <Tooltip label={expirationLabel} withArrow>
                        <div>
                          <IconAlertTriangle className="fill-none stroke-doubleNickel-warning-500" />
                        </div>
                      </Tooltip>
                    )}
                    {anyExpiredDocument && (
                      <Tooltip label={`All documents have expired`} withArrow>
                        <div>
                          <IconAlertTriangle className="fill-none stroke-doubleNickel-error-500" />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                  <Menu shadow="md" position="bottom-end">
                    <Menu.Target>
                      <div>
                        <StyledBadge
                          textTransform="capitalize"
                          color={colorByStatus[step.status]}
                          rightSection={<IconChevronDown />}
                        >
                          {formatSnakeValue(step.status)}
                        </StyledBadge>
                      </div>
                    </Menu.Target>
                    <Menu.Dropdown className="w-40">
                      {statuses.map((status, index) => (
                        <Menu.Item
                          key={index}
                          className="text-doubleNickel-gray-700"
                          onClick={() => {
                            updateStep({
                              variables: {
                                id: step.stepId,
                                input: {
                                  // type,
                                  status,
                                },
                              },
                            })
                          }}
                        >
                          {formatSnakeValue(status)}
                        </Menu.Item>
                      ))}
                    </Menu.Dropdown>
                  </Menu>
                </Box>
              )
            })}
          </div>
        </div>
      </div>

      <div className="flex flex-row gap-4">
        <Divider orientation="vertical" />
        <div className="flex flex-1 flex-col gap-4 overflow-y-auto overflow-x-hidden">
          <Box className="font-lg flex flex-row items-center justify-between font-bold text-doubleNickel-gray-700">
            <div className="flex flex-row items-center gap-2">
              {selected.type && formatStepType(selected.type)}
              <IconRightArrow className="h-3 fill-none stroke-doubleNickel-gray-900" />
              <div>Documents</div>
            </div>

            <div className="flex flex-row gap-4">
              <Button
                text="Upload"
                variant="outline"
                lefticon={<IconUploadSimple />}
                onClick={() => handleUpload(selected)}
              />
            </div>
          </Box>
          {((documentsResponse && documentsResponse.length === 0) ||
            !selected.type) && (
            <div className="flex flex-1 flex-col items-center justify-center">
              <IconEmptyInbox className="fill-none" />
              <div className="text-sm font-medium text-doubleNickel-gray-600">
                {selected.type
                  ? 'There are no documents for this step'
                  : 'Please select a step to see the corresponding files'}
              </div>
            </div>
          )}

          {currentDocuments?.length > 0 && (
            <div>
              <div className="text-sm font-semibold text-doubleNickel-gray-600 ">
                Current
              </div>
              {currentDocuments.map((document) => {
                return (
                  <DQFDocument
                    key={document.documentId}
                    step={selected}
                    document={document}
                    onDelete={onDelete}
                    onRenewed={onRenewed}
                    onPreview={onPreview}
                    onDownload={onDownload}
                    onEdit={onEdit}
                  />
                )
              })}
            </div>
          )}

          {expiredDocuments?.length > 0 && (
            <div>
              <div className="text-sm font-semibold text-doubleNickel-gray-600 ">
                Expired
              </div>
              {expiredDocuments.map((document) => {
                return (
                  <DQFDocument
                    key={document.documentId}
                    step={selected}
                    document={document}
                    onDelete={onDelete}
                    onRenewed={onRenewed}
                    onPreview={onPreview}
                    onDownload={onDownload}
                    onEdit={onEdit}
                  />
                )
              })}
            </div>
          )}

          {renewedDocuments?.length > 0 && (
            <div>
              <div className="text-sm font-semibold text-doubleNickel-gray-600 ">
                Renewed
              </div>
              {renewedDocuments.map((document) => {
                return (
                  <DQFDocument
                    key={document.documentId}
                    step={selected}
                    document={document}
                    onDelete={onDelete}
                    onRenewed={onRenewed}
                    onPreview={onPreview}
                    onDownload={onDownload}
                    onEdit={onEdit}
                  />
                )
              })}
            </div>
          )}
        </div>
      </div>
      <RequestOrderModal
        refetchQueries={refetchQueries}
        isModalOpen={requestOrderModalOpened}
        onModalClose={closeRequestOrderModal}
        applicantId={applicantId}
      />
    </div>
  )
}

export default DQFContent
