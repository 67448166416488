import { useState } from 'react'

import { Divider, Checkbox, Modal, Progress, Tooltip } from '@mantine/core'
import dayjs from 'dayjs'

import Button from 'src/components/Buttons/Button/Button'
import QuestionRow from 'src/components/Inputs/QuestionRow/QuestionRow'

import CompanyAutocomplete from '../../CompanyAutocomplete/CompanyAutocomplete'
import MonthInput from '../../MonthInput/MonthInput'
import TextInput from '../../TextInput/TextInput'
import PhoneInput from 'src/components/Inputs/PhoneInput/PhoneInput'
import GooglePlacePicker from '../../AddressInput/GooglePlacePicker'
import { formatAddress } from 'src/lib/address.utils'

import { removeCountryCode, unformatPhoneNumber } from 'src/lib/phone.utils'

const DATE_FORMAT = 'MM/DD/YYYY'

const EditEmploymentModal = ({
  opened,
  close,
  experiences,
  setExperiences,
  currentIndex,
  handleSave,
  handleCancel,
}) => {
  const [page, setPage] = useState(1)
  const item = experiences[currentIndex]

  const updateExperience = (key, value) => {
    const tempObj = { ...experiences[currentIndex] }
    tempObj[key] = value
    const tempArray = [...experiences]
    tempArray[currentIndex] = tempObj
    setExperiences(tempArray)
  }

  const handleCompanySelected = (selected) => {
    const tempObj = { ...experiences[currentIndex] }
    tempObj.companyName = selected.companyName
    tempObj.dotNumber = selected.dotNumber
    tempObj.address = { ...selected.address }

    if (selected.phone) {
      tempObj.phone = selected.phone
    }

    const tempArray = [...experiences]
    tempArray[currentIndex] = tempObj
    setExperiences(tempArray)
  }

  const updateAddress = (address) => {
    const tempObj = { ...experiences[currentIndex] }
    tempObj.address = { ...address }
    const tempArray = [...experiences]
    tempArray[currentIndex] = tempObj
    setExperiences(tempArray)
  }

  const handleSubmit = () => {
    handleSave()
    close()
  }

  const onCancel = () => {
    handleCancel()
    close()
  }

  const isEmpty = (field) => {
    return field === undefined || field === '' || field === null
  }

  const emptyRequiredFields = () => {
    const isMissingRequiredFields =
      isEmpty(item.companyName) ||
      isEmpty(item.position) ||
      isEmpty(item.startDate)

    return isMissingRequiredFields
  }

  const validatePhone = (phone) => {
    if (!phone) return

    const phoneLength = unformatPhoneNumber(removeCountryCode(phone)).length
    if (phoneLength !== 10 && phoneLength !== 0) {
      return 'Number must be 10 digits'
    }
  }

  const validateFields = () => {
    const hasErrors = validatePhone(item.phone) || validatePhone(item.faxNumber)
    return hasErrors
  }

  return (
    <Modal
      opened={opened}
      onClose={close}
      closeOnClickOutside={false}
      closeOnEscape={false}
      withCloseButton={false}
      size="50%"
      centered
      title={
        item.companyName
          ? 'Edit Employment Experience  [' + item.companyName + ']'
          : 'Add Employment Experience'
      }
    >
      <div className="flex flex-col gap-4">
        <Divider />
        <Progress value={page === 1 ? 50 : 100} />
        <div className="text-sm font-semibold text-doubleNickel-gray-900">
          Please fill out the following fields
        </div>
        {page === 1 && (
          <div className="grid grid-cols-2 gap-3">
            <CompanyAutocomplete
              label={'Company Name'}
              editing={true}
              classNames={{
                root: 'flex-1',
                label: 'text-doubleNickel-gray-700 text-sm',
                input: 'text-md font-semibold',
              }}
              required
              value={item.companyName}
              onChange={(value) => updateExperience('companyName', value)}
              onSelect={(selected) => handleCompanySelected(selected)}
            />
            <TextInput
              label={'Position'}
              classNames={{
                root: 'flex-1',
                label: 'text-doubleNickel-gray-700 text-sm',
              }}
              editing={true}
              required
              value={item.position}
              handleChange={(value) => updateExperience('position', value)}
            />
            <MonthInput
              label={'Start Date'}
              classNames={{
                root: 'flex-1',
                label: 'text-doubleNickel-gray-700 text-sm',
              }}
              required
              editing={true}
              _maxDate={item.endDate || new Date().toISOString().split('T')[0]}
              value={
                item.startDate
                  ? dayjs(item.startDate).format(DATE_FORMAT)
                  : null
              }
              handleChange={(value) => updateExperience('startDate', value)}
            />
            <div className="flex flex-col">
              <MonthInput
                label={'End Date'}
                classNames={{
                  root: 'flex-1',
                  label: 'text-doubleNickel-gray-700 text-sm',
                }}
                editing={item.endDate ? true : false}
                value={
                  item.endDate ? dayjs(item.endDate).format(DATE_FORMAT) : null
                }
                handleChange={(value) => updateExperience('endDate', value)}
                _minDate={item.startDate}
                _maxDate={new Date().toISOString().split('T')[0]}
              />

              <Checkbox
                size="xs"
                label="Current employment"
                className="mt-2"
                checked={!item.endDate}
                onChange={(event) => {
                  event.stopPropagation()
                  if (event.currentTarget.checked) {
                    updateExperience('endDate', null)
                  } else {
                    updateExperience('endDate', new Date())
                  }
                }}
              />
            </div>
          </div>
        )}
        {page === 2 && (
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-3 gap-3">
              <TextInput
                label="Dot Number"
                placeholder="Enter DOT Number"
                editing={true}
                value={item.dotNumber}
                handleChange={(value) => updateExperience('dotNumber', value)}
              />
              <TextInput
                label="Contact Name"
                placeholder="Enter Contact Name"
                editing={true}
                value={item.contactName}
                handleChange={(value) => updateExperience('contactName', value)}
              />
              <TextInput
                label="Email Address"
                placeholder="Enter Email Address"
                editing={true}
                value={item.email}
                handleChange={(value) => updateExperience('email', value)}
              />
              <PhoneInput
                label="Phone"
                value={item.phone}
                error={validatePhone(item.phone)}
                onChange={(value) => {
                  updateExperience('phone', value)
                }}
              />
              <PhoneInput
                label="Fax Number"
                value={item.faxNumber}
                error={validatePhone(item.faxNumber)}
                onChange={(value) => updateExperience('faxNumber', value)}
              />
            </div>

            <GooglePlacePicker
              label="Address"
              value={item.address}
              handleChange={(address) => updateAddress(address)}
            />

            <TextInput
              label="Reason for leaving"
              placeholder="Enter Reason for Leaving"
              editing={true}
              value={item.reasonForLeaving}
              handleChange={(value) =>
                updateExperience('reasonForLeaving', value)
              }
            />
            <Divider />
            <QuestionRow
              editing={true}
              text="Was this driver terminated?"
              value={item.isDriverTerminated}
              handleChange={(event) =>
                updateExperience('isDriverTerminated', event === 'true')
              }
            />
            <QuestionRow
              editing={true}
              text="Was the driver subject to FMCSRs while employed?"
              value={item.isDriverSubjectToFMCSRs}
              handleChange={(event) =>
                updateExperience('isDriverSubjectToFMCSRs', event === 'true')
              }
            />
            <QuestionRow
              editing={true}
              text="Was the job designated as a safety-sensitive function in any Department of Transportation-regulated mode subject to alcohol and controlled substances testing as required by 49 CFR, part 40?"
              value={item.isJobDesignatedAsSafetySensitiveFunctionUnderDOT}
              handleChange={(event) =>
                updateExperience(
                  'isJobDesignatedAsSafetySensitiveFunctionUnderDOT',
                  event === 'true'
                )
              }
            />
            <QuestionRow
              editing={true}
              text="Contact Employer?"
              value={item.shouldContactEmployer}
              handleChange={(event) =>
                updateExperience('shouldContactEmployer', event === 'true')
              }
            />
          </div>
        )}
        <Divider />
        <div className="flex flex-row items-center justify-between gap-2">
          <Button
            variant="outline"
            text={page === 1 ? 'Cancel' : 'Back'}
            onClick={page === 1 ? () => onCancel() : () => setPage(1)}
            className="flex-1 text-doubleNickel-gray-700"
          />
          <Tooltip
            label={'Please fill out required fields'}
            position="top"
            disabled={page === 1 ? !emptyRequiredFields() : !validateFields()}
          >
            <Button
              text={page === 1 ? 'Next' : 'Submit'}
              disabled={page === 1 ? emptyRequiredFields() : validateFields()}
              onClick={page === 1 ? () => setPage(2) : () => handleSubmit()}
              className="flex-1"
            />
          </Tooltip>
        </div>
      </div>
    </Modal>
  )
}

export default EditEmploymentModal
