import { useCallback, useState } from 'react'

import { Modal, TextInput, Select, Group, Stack } from '@mantine/core'
import { useForm } from '@mantine/form'
import { zodResolver } from 'mantine-form-zod-resolver'
import { DocumentTemplatePackage } from 'types/graphql'
import { z } from 'zod'

import { useQuery, useMutation } from '@redwoodjs/web'

import Button from 'src/components/Buttons/Button/Button'
import StyledAlert from 'src/components/Feedback/StyledAlert/StyledAlert'
import { toast } from 'src/components/Overlays/Toast/Toast'
import {
  GET_DOCUMENT_TEMPLATE_PACKAGES,
  SIGN_PACKAGE,
} from 'src/graphql/documentTemplatePackages.graphql'
import IconPlane from 'src/icons/IconPlane'
import { formatSnakeValue } from 'src/lib/formatters'

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}

const onSuccess = () => {
  toast('Documents sent successfully', 'success')
}

interface OptionType {
  value: string
  label: string
  documentTemplatePackage: DocumentTemplatePackage
  disabled?: boolean
  seeMore?: boolean
}

const SendDocumentsToSignModal = ({ applicantId, email, isOpen, onClose }) => {
  const [errorMessage, setErrorMessage] = useState(null)

  const { data } = useQuery(GET_DOCUMENT_TEMPLATE_PACKAGES, {
    onError: (err) => {
      setErrorMessage(err)
      onError()
    },
  })

  const [signPackage, { loading: signPackageLoading }] = useMutation(
    SIGN_PACKAGE,
    {
      onCompleted: ({ signPackage }) => {
        if (signPackage) {
          onSuccess()
          onClose()
        }
      },
      onError: (error) => {
        setErrorMessage(error.message)
        onError()
      },
    }
  )

  const packagesList =
    data?.documentTemplatePackages?.map((documentTemplatePackage) => ({
      value: documentTemplatePackage.documentTemplatePackageId,
      label: `${formatSnakeValue(documentTemplatePackage.packageName)}`,
      documentTemplatePackage,
    })) || []

  const schema = z.object({
    email: z.string().email({ message: 'Invalid email' }),
    documentTemplatePackageId: z.string().min(1, 'Please select a package'),
  })

  const form = useForm({
    initialValues: {
      email,
      documentTemplatePackageId: '',
    },
    validateInputOnBlur: true,
    validate: zodResolver(schema),
  })

  const handleSubmit = useCallback((values) => {
    signPackage({
      variables: {
        applicantEmail: values.email,
        documentTemplatePackageId: values.documentTemplatePackageId,
        applicantId,
      },
    })
  }, [])

  const handleError = useCallback((err) => {
    if (err.documentTemplatePackageId || err.email) {
      setErrorMessage(err)
    } else {
      onError()
    }
  }, [])

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      classNames={{ title: 'text-lg font-bold' }}
      title="Send Documents for Signing"
      centered
    >
      <form
        onSubmit={form.onSubmit((values) => handleSubmit(values), handleError)}
      >
        <div className="flex flex-col gap-6">
          <TextInput
            placeholder={'Email'}
            required
            label="Email"
            classNames={{
              label: 'text-sm font-medium text-doubleNickel-gray-700',
            }}
            disabled={!packagesList?.length}
            {...form.getInputProps('email')}
          />
          <Select
            {...form.getInputProps('documentTemplatePackageId')}
            label="Package"
            placeholder="Select"
            withAsterisk={true}
            required
            data={packagesList}
            disabled={!packagesList.length}
            error={!!errorMessage?.documentTemplatePackageId}
            renderOption={renderSelectOption}
            classNames={{
              label: 'text-sm font-medium text-doubleNickel-gray-700',
              input: 'text-sm text-doubleNickel-gray-700',
            }}
          />
          {!packagesList.length && (
            <StyledAlert
              variant="warning"
              description={
                <span data-testid="warning-message">
                  Currently, this functionality is not enabled.
                  <br />
                  Please contact the Double Nickel team to enable it and
                  accelerate your hiring process!
                </span>
              }
            />
          )}
        </div>

        <Group
          justify="flex-end"
          mt="md"
          className="grid grid-cols-2 gap-4 border-t py-4"
        >
          <Button text="Cancel" variant="outline" onClick={onClose} />
          <Button
            text="Send"
            variant="filled"
            type="submit"
            lefticon={<IconPlane />}
            loading={signPackageLoading}
            disabled={!packagesList?.length}
          />
        </Group>
      </form>
    </Modal>
  )
}

const DocumentOption = ({ option }: { option: OptionType }) => {
  const [seeMore, setSeeMore] = useState(false)
  return (
    <Stack flex="1" gap="2" className="text-doubleNickel-gray-700">
      <div className="flex flex-row justify-between gap-1">
        <div className="text-xs font-medium">{option.label}</div>
        <button
          className="see-more-button text-xs font-medium text-blue-500"
          onClick={(event) => {
            event.stopPropagation()
            setSeeMore(!seeMore)
          }}
        >
          {seeMore ? 'Hide documents' : 'Show documents'}
        </button>
      </div>
      <Group flex="1" gap="5">
        <ul className="ml-2 mt-1">
          {option.documentTemplatePackage.documentTemplates
            .slice(
              0,
              seeMore
                ? option.documentTemplatePackage.documentTemplates.length
                : 0
            )
            .map((documentTemplate) => (
              <li
                key={documentTemplate.documentTemplateId}
                className="font-regular mb-1 text-xs"
              >
                {documentTemplate.documentName}
              </li>
            ))}
        </ul>
      </Group>
    </Stack>
  )
}

const renderSelectOption = ({ option }: { option: OptionType }) => (
  <DocumentOption option={option} />
)

export default SendDocumentsToSignModal
