import { useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { Modal, Select, Divider, Stack, Group } from '@mantine/core'
import { useForm } from '@mantine/form'
import { StepType } from 'types/graphql'

import { useQuery, useMutation } from '@redwoodjs/web'

import Button from 'src/components/Buttons/Button/Button'
import StyledBadge from 'src/components/DataDisplay/StyledBadge/StyledBadge'
import StyledAlert from 'src/components/Feedback/StyledAlert/StyledAlert'
import { toast } from 'src/components/Overlays/Toast/Toast'
import { REQUEST_ORDER_ASURINT } from 'src/graphql/asurint.graphql'
import { REQUEST_PACKAGES } from 'src/graphql/packages.graphql'
import { formatStepType, formatSnakeValue } from 'src/lib/formatters'

interface RequestOrderModalProps {
  isModalOpen: boolean
  onModalClose: () => void
  refetchQueries?: any[]
  applicantId: string
}

interface OptionType {
  value: string
  label: string
  stepTypes: StepType[]
  disabled?: boolean
}

const RequestOrderModal = ({
  isModalOpen,
  onModalClose,
  refetchQueries,
  applicantId,
}: RequestOrderModalProps) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const [warningVisible, setWarningVisible] = useState(false)
  const apolloClient = useApolloClient()

  const form = useForm({
    initialValues: {
      packageId: '',
    },
  })

  const { data } = useQuery(REQUEST_PACKAGES)
  const packagesList =
    data?.packages?.map((p) => ({
      value: p.packageId,
      label: `${formatSnakeValue(p.packageType)} - ${p.name}`,
      stepTypes: p.stepType,
    })) || []

  const resetAlerts = () => {
    setWarningVisible(false)
    setErrorMessage(null)
  }

  const errorMessageWithUrl = (url) => (
    <span data-testid="error-message">
      A problem has been identified; please follow the link to modify invalid or
      missing information. After using the link, you can close this modal, as
      your order will be processed through the provided link:&nbsp;
      <a className="underline" target="_blank" href={url} rel="noreferrer">
        Order Link
      </a>
    </span>
  )

  const handleSubmit = (values) => {
    resetAlerts()
    requestOrder({ variables: { packageId: values.packageId, applicantId } })
  }

  const closeModal = () => {
    form.reset()
    resetAlerts()
    onModalClose()
  }

  const [requestOrder, { loading: requestLoading }] = useMutation(
    REQUEST_ORDER_ASURINT,
    {
      onCompleted: (res) => {
        const { orderId, orderUrl } = res?.orderResponse

        if (orderId) {
          apolloClient.refetchQueries({ include: refetchQueries })
          toast(`Order requested successfuly. Number: ${orderId}.`, 'success')
          closeModal()
        }

        if (orderUrl) {
          setErrorMessage(errorMessageWithUrl(orderUrl))
        }
      },
      onError: (error) => {
        console.log(error)
        setErrorMessage(error.message)
      },
    }
  )

  const renderSelectOption = ({ option }: { option: OptionType }) => (
    <Stack flex="1" gap="2" className="font-medium text-doubleNickel-gray-700">
      {option.label}
      <Group flex="1" gap="5">
        {option.stepTypes.map((s) => (
          <StyledBadge key={s}>{formatStepType(s, true)}</StyledBadge>
        ))}
      </Group>
    </Stack>
  )

  return (
    <Modal
      opened={isModalOpen}
      onClose={closeModal}
      classNames={{ title: 'text-xl font-bold' }}
      title="Request an order"
      centered
      size="42%"
    >
      <form
        className="grid grid-cols-1 gap-4"
        onSubmit={form.onSubmit(handleSubmit)}
      >
        <Select
          {...form.getInputProps('packageId')}
          label="Select the provider and the package you need."
          placeholder="Select"
          withAsterisk={false}
          required
          data={packagesList}
          disabled={!packagesList.length || requestLoading}
          error={!!errorMessage}
          renderOption={renderSelectOption}
          classNames={{
            label: 'text-sm font-medium text-doubleNickel-gray-700',
            input: 'text-base text-doubleNickel-gray-500',
          }}
        />

        {(warningVisible || !packagesList.length) && (
          <StyledAlert
            variant="warning"
            description={
              <span data-testid="warning-message">
                Currently, this functionality is not enabled.
                <br />
                Please contact the Double Nickel team to enable it and
                accelerate your hiring process!
              </span>
            }
          />
        )}

        {errorMessage && (
          <StyledAlert variant="error" description={errorMessage} />
        )}

        <Divider my="md" />
        <div className="grid grid-cols-2 gap-3">
          <Button
            text="Cancel"
            className="text-base"
            variant="outline"
            onClick={closeModal}
          />
          <Button
            text="Confirm"
            className="text-base"
            type="submit"
            disabled={!form.values.packageId}
            loading={requestLoading}
          />
        </div>
      </form>
    </Modal>
  )
}

export default RequestOrderModal
