import { Modal, TextInput, Select, Group } from '@mantine/core'
import { useForm } from '@mantine/form'
import { zodResolver } from 'mantine-form-zod-resolver'
import { z } from 'zod'

import { useMutation } from '@redwoodjs/web'

import Button from 'src/components/Buttons/Button/Button'
import { toast } from 'src/components/Overlays/Toast/Toast'
import { VoEStatus } from 'src/graphql/types/verificationOfEmployments'
import { UPDATE_VOE } from 'src/graphql/voe.graphql'
import { formatSnakeValue } from 'src/lib/formatters'
import PhoneInput from 'src/components/Inputs/PhoneInput/PhoneInput'

import { removeCountryCode, unformatPhoneNumber } from 'src/lib/phone.utils'

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}

const statuses = Object.values(VoEStatus)

const EditVoEModal = ({ voe, isOpen, onRender, onClose }) => {
  const schema = z.object({
    dotNumber: z
      .string()
      .max(50, { message: 'DoT number should have at most 50 letters' })
      .optional()
      .or(z.literal('')),
    contactName: z
      .string()
      .max(50, { message: 'Contact name should have at most 50 letters' })
      .optional()
      .or(z.literal('')),
    email: z
      .string()
      .email({ message: 'Invalid email' })
      .optional()
      .or(z.literal('')),
    phoneNumber: z
      .string()
      .refine(
        (data) =>
          unformatPhoneNumber(removeCountryCode(data)).length === 10 ||
          unformatPhoneNumber(removeCountryCode(data)).length === 0,
        {
          message: 'Phone number should have 10 digits',
        }
      )
      .optional()
      .or(z.literal('')),
    faxNumber: z
      .string()
      .refine(
        (data) =>
          unformatPhoneNumber(removeCountryCode(data)).length === 10 ||
          unformatPhoneNumber(removeCountryCode(data)).length === 0,
        {
          message: 'Phone number should have 10 digits',
        }
      )
      .optional()
      .or(z.literal('')),
  })

  const form = useForm({
    initialValues: {
      dotNumber: voe.dotNumber || '',
      status: voe.status || '',
      contactName: voe.contactName || '',
      email: voe.email || '',
      phoneNumber: voe.phoneNumber || '',
      faxNumber: voe.faxNumber || '',
    },
    validate: zodResolver(schema),
  })

  const [updateVoE] = useMutation(UPDATE_VOE, {
    refetchQueries: onRender,
    onCompleted: () => {
      toast('Verification of employment updated successfully', 'success')
      onClose()
    },
    onError,
  })

  const onFormSubmit = (values) => {
    updateVoE({
      variables: {
        id: voe.voeId,
        input: {
          ...values,
        },
      },
    })
  }

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      classNames={{ title: 'text-lg font-bold' }}
      title="Edit verification of employment"
      centered
    >
      <div className="flex flex-col gap-6">
        <form onSubmit={form.onSubmit((values) => onFormSubmit(values))}>
          <div className="flex flex-col">
            <div className="grid grid-cols-2 gap-4">
              <Select
                withAsterisk
                label="Status"
                placeholder="Select status"
                data={statuses.map((status) => ({
                  value: status,
                  label: formatSnakeValue(status),
                }))}
                required
                disabled={voe.status === VoEStatus.COMPLETED}
                {...form.getInputProps('status')}
              />
              <TextInput
                className="col-span-2"
                placeholder={
                  form.getInputProps('dotNumber').value
                    ? form.getInputProps('dotNumber').value.name
                    : 'DoT Number'
                }
                label="DoT Number"
                {...form.getInputProps('dotNumber')}
              />
              <TextInput
                className="col-span-2"
                placeholder={
                  form.getInputProps('contactName').value
                    ? form.getInputProps('contactName').value.name
                    : 'Contact name'
                }
                label="Contact name"
                {...form.getInputProps('contactName')}
              />
              <TextInput
                className="col-span-2"
                placeholder={
                  form.getInputProps('email').value
                    ? form.getInputProps('email').value.name
                    : 'Email address'
                }
                label="Email address"
                {...form.getInputProps('email')}
              />
              <PhoneInput
                className="col-span-2"
                placeholder={
                  form.getInputProps('faxNumber').value
                    ? form.getInputProps('faxNumber').value.name
                    : 'Fax number'
                }
                label="Fax number"
                {...form.getInputProps('faxNumber')}
              />
              <PhoneInput
                className="col-span-2"
                placeholder={
                  form.getInputProps('phoneNumber').value
                    ? form.getInputProps('phoneNumber').value.name
                    : 'Phone number'
                }
                label="Phone number"
                {...form.getInputProps('phoneNumber')}
              />
            </div>
            <Group
              justify="flex-end"
              mt="md"
              className="grid grid-cols-2 gap-4 border-t py-4"
            >
              <Button text="Cancel" variant="outline" onClick={onClose} />
              <Button type="submit" text="Save" variant="filled" />
            </Group>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default EditVoEModal
