import { Table, Menu, ActionIcon, Anchor } from '@mantine/core'
import { useMutation } from '@redwoodjs/web'
import dayjs from 'dayjs'

import StyledBadge from 'src/components/DataDisplay/StyledBadge/StyledBadge'
import StyledMenu from 'src/components/Overlays/StyledMenu/StyledMenu'
import { toast } from 'src/components/Overlays/Toast/Toast'
import { UPDATE_DOCUMENT } from 'src/graphql/documents.graphql'
import {
  DocumentUserStatus,
  DocumentCategory,
} from 'src/graphql/types/documents'
import IconChevronDown from 'src/icons/IconChevronDown'
import IconEmptyInbox from 'src/icons/IconEmptyInbox'
import IconThreeDots from 'src/icons/IconThreeDots'
import {
  formatSnakeValue,
  buildFullName,
  formatDocumentCategory,
} from 'src/lib/formatters'

const colorByStatus = {
  PENDING_REVIEW: 'orange',
  COMPLETED: 'green',
}

const categories = Object.values(DocumentCategory).map((category) => ({
  label: formatDocumentCategory(category),
  value: category,
}))

const statuses = Object.values(DocumentUserStatus)

const DocumentsTable = ({
  documents = [],
  loading,
  onPreview,
  onEdit,
  onDownload,
  onDelete,
  onRenewed,
  refetchQueries,
}) => {
  const [updateDocument] = useMutation(UPDATE_DOCUMENT, {
    refetchQueries: refetchQueries,
    onCompleted: () => {
      toast('Document updated successfully', 'success')
    },
    onError: () => {
      toast('Something went wrong, please try again.', 'error')
    },
  })

  const rows = loading ? (
    <></>
  ) : (
    documents.map((document) => (
      <Table.Tr
        key={document.documentId}
        className="text-sm text-doubleNickel-gray-900"
      >
        <Table.Td className="text-nowrap py-2 pl-4">
          <StyledMenu>
            <Menu.Target>
              <div className="flex flex-row items-center gap-1">
                {formatDocumentCategory(document.category)}
                <ActionIcon variant="subtle">
                  <IconChevronDown className="h-3 fill-none stroke-doubleNickel-gray-600" />
                </ActionIcon>
              </div>
            </Menu.Target>
            <Menu.Dropdown className="h-80 w-40 overflow-auto">
              {categories.map((cat, index) => (
                <Menu.Item
                  key={index}
                  className="text-doubleNickel-gray-700"
                  onClick={() => {
                    if (cat.value !== document.category) {
                      updateDocument({
                        variables: {
                          id: document.documentId,
                          input: {
                            category: cat.value,
                          },
                        },
                      })
                    }
                  }}
                >
                  {cat.label}
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </StyledMenu>
        </Table.Td>
        <Table.Td>
          <StyledMenu>
            <Menu.Target>
              <div>
                <StyledBadge
                  clickable
                  textTransform="capitalize"
                  color={colorByStatus[document.userStatus]}
                  rightSection={<IconChevronDown className="h-3" />}
                >
                  {formatSnakeValue(document.userStatus)}
                </StyledBadge>
              </div>
            </Menu.Target>
            <Menu.Dropdown className="w-40">
              {statuses.map((status, index) => (
                <Menu.Item
                  key={index}
                  className="text-doubleNickel-gray-700"
                  onClick={() => {
                    if (status !== document.userStatus) {
                      updateDocument({
                        variables: {
                          id: document.documentId,
                          input: {
                            userStatus: status,
                          },
                        },
                      })
                    }
                  }}
                >
                  {formatSnakeValue(status)}
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </StyledMenu>
        </Table.Td>
        <Table.Td>
          <Anchor
            className="line-clamp-2 hyphens-auto break-all text-sm text-doubleNickel-gray-900 hover:text-doubleNickel-black"
            onClick={() => onPreview(document)}
          >
            {document.fileName}
          </Anchor>
        </Table.Td>
        <Table.Td>{dayjs(document.createdAt).format('MM/DD/YYYY')}</Table.Td>
        <Table.Td>
          <div className="flex flex-row items-center gap-4">
            {`${buildFullName(document?.uploadedBy) || '-'}`}
            <Menu shadow="md" position="bottom-end">
              <Menu.Target>
                <ActionIcon variant="subtle" className="h-6 w-6">
                  <IconThreeDots className="fill-doubleNickel-white stroke-doubleNickel-gray-600" />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown className="w-40">
                <Menu.Item
                  className="text-doubleNickel-gray-700"
                  onClick={() => onPreview(document)}
                >
                  View
                </Menu.Item>
                <Menu.Item
                  className="text-doubleNickel-gray-700"
                  onClick={() => onEdit(document, null)}
                >
                  Edit
                </Menu.Item>
                <Menu.Item
                  className="text-doubleNickel-gray-700"
                  onClick={() => onDownload(document.documentId)}
                >
                  Download
                </Menu.Item>
                <Menu.Item
                  className="text-doubleNickel-gray-700"
                  onClick={() =>
                    onRenewed(document.documentId, !document.isRenewed)
                  }
                >
                  Mark as {document.isRenewed ? 'not renewed' : 'renewed'}
                </Menu.Item>
                <Menu.Item
                  disabled={Boolean(document.voe)}
                  className="text-doubleNickel-gray-700"
                  onClick={() => onDelete(document.documentId, null)}
                >
                  Delete
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </div>
        </Table.Td>
      </Table.Tr>
    ))
  )

  return (
    <>
      <Table highlightOnHover stickyHeader>
        <Table.Thead>
          <Table.Tr className="h-11 border-none bg-doubleNickel-gray-50 text-xs text-doubleNickel-gray-600">
            <Table.Th className="p-4">Category</Table.Th>
            <Table.Th className="py-4">Status</Table.Th>
            <Table.Th className="py-4">File Name</Table.Th>
            <Table.Th className="py-4">Created At</Table.Th>
            <Table.Th className="py-4">Uploaded By</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
        <Table.Caption>
          {documents.length === 0 && !loading && (
            <div className="flex flex-1 flex-col items-center justify-center  ">
              <IconEmptyInbox className="h-40 fill-none" />
              <div className="text-md font-medium text-doubleNickel-gray-800">
                No Documents Found
              </div>
              <div className="text-sm text-doubleNickel-gray-600">
                Upload a new document to get started
              </div>
            </div>
          )}
        </Table.Caption>
      </Table>
    </>
  )
}

export default DocumentsTable
