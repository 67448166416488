import { Timeline } from '@mantine/core'

const TrainingAuditTrail = ({}) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col text-base font-semibold text-doubleNickel-black">
        Activity
      </div>
      <Timeline
        bulletSize={12}
        lineWidth={2}
        classNames={{
          itemTitle: 'h-0',
        }}
      >
        <Timeline.Item>
          <div className="flex flex-row gap-1 text-sm text-doubleNickel-gray-500">
            <div className="font-medium text-doubleNickel-gray-900">
              Ariel Serruya
            </div>
            assigned the Safety and Handling Course · 3 days ago
          </div>
        </Timeline.Item>

        <Timeline.Item>
          <div className="flex flex-row gap-1 text-sm text-doubleNickel-gray-500">
            <div className="font-medium text-doubleNickel-gray-900">
              Oscar Lopez
            </div>
            started the Safety and Handling Course · 3 days ago
          </div>
        </Timeline.Item>
        <Timeline.Item>
          <div className="flex flex-row gap-1 text-sm text-doubleNickel-gray-500">
            <div className="font-medium text-doubleNickel-gray-900">
              Oscar Lopez
            </div>
            completed 50% of the Safety and Handling Course · 1 days ago
          </div>
        </Timeline.Item>
        <Timeline.Item>
          <div className="flex flex-row gap-1 text-sm text-doubleNickel-gray-500">
            <div className="font-medium text-doubleNickel-gray-900">
              Oscar Lopez
            </div>
            completed the Safety and Handling Course · today
          </div>
        </Timeline.Item>
        <Timeline.Item>
          <div className="flex flex-row gap-1 text-sm text-doubleNickel-gray-500">
            <div className="font-medium text-doubleNickel-gray-900">
              Ariel Serruya
            </div>
            assigned the Preventative Driving Course · 2 hours ago
          </div>
        </Timeline.Item>
      </Timeline>
    </div>
  )
}

export default TrainingAuditTrail
