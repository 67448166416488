import dayjs from 'dayjs'

export const willDateExpireSoon = (date, limit) => {
  if (!date) return { isDateExpiring: false, daysUntilDueDate: 0 }

  const today = dayjs().endOf('day')
  const expirationDate = dayjs(new Date(date)).endOf('day')
  const daysUntilDueDate = expirationDate.diff(today, 'day')

  const isDateExpiring = limit
    ? daysUntilDueDate <= limit && daysUntilDueDate >= 0
    : false

  const hasExpired = daysUntilDueDate < 0

  return { hasExpired, isDateExpiring, daysUntilDueDate }
}
