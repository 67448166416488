import { NetworkStatus } from '@apollo/client'
import { Loader, Timeline } from '@mantine/core'

import { ActivityLogTimelineItem } from './ActivityLogTimelineItem'

export const ActivityLogTimeline = ({
  applicantId,
  activityLogsResponse,
  networkStatus,
  refParent,
}) => {
  const groupActivityLogsByDate = (activityLogs) => {
    if (!activityLogs) {
      return {}
    }
    return activityLogs.reduce((acc, activityLog) => {
      const date = new Date(activityLog.createdAt).toDateString()
      if (!acc[date]) {
        acc[date] = []
      }
      acc[date].push(activityLog)
      return acc
    }, {})
  }

  const groupedActivityLogs = groupActivityLogsByDate(
    activityLogsResponse?.activityLogs.items
  )

  const groupedActivityLogsArray = Object.keys(groupedActivityLogs).map(
    (date) => {
      return {
        date,
        activityLogs: groupedActivityLogs[date],
      }
    }
  )

  return (
    <div className="flex flex-col gap-10">
      {groupedActivityLogsArray.map((activityLogGroup) => (
        <div className="flex flex-col gap-4">
          <div className="text-sm font-semibold text-doubleNickel-gray-700">
            {activityLogGroup.date}
          </div>
          <Timeline
            active={4}
            bulletSize={7}
            lineWidth={1}
            color="black"
            classNames={{
              itemBullet:
                'mt-1.5 bg-doubleNickel-gray-500 border-doubleNickel-gray-500',
              itemBody: 'ml-4',
            }}
          >
            {activityLogGroup.activityLogs.map((activityLog) => (
              <ActivityLogTimelineItem
                key={activityLog.activityLogId}
                activityLog={activityLog}
                applicantId={applicantId}
              />
            ))}
          </Timeline>
        </div>
      ))}
      {networkStatus === NetworkStatus.fetchMore && (
        <Loader size="sm" className="flex w-full flex-row justify-center" />
      )}
      {activityLogsResponse?.activityLogs.items.length <
        activityLogsResponse?.activityLogs.totalCount && (
        <div ref={refParent} className="opacity-0">
          Inview placeholder
        </div>
      )}
    </div>
  )
}
