import { useCallback, useEffect, useState } from 'react'

import {
  Modal,
  Divider,
  Tooltip,
  Select,
  Loader,
  ComboboxItem,
  ComboboxItemGroup,
} from '@mantine/core'

import { navigate, routes } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import Button from 'src/components/Buttons/Button/Button'
import { toast } from 'src/components/Overlays/Toast/Toast'
import { COPY_APPLICATION } from 'src/graphql/application.graphql'
import { GET_JOB_LISTINGS } from 'src/graphql/joblistings.graphql'
import {
  GET_MY_COMPANIES,
  UPDATE_CURRENT_EMPLOYEE_ID,
} from 'src/graphql/users.graphql'
import { formatSnakeValue } from 'src/lib/formatters'

import { ApplicantSharingModalFeedback } from '../ApplicantSharingModalFeedback/ApplicantSharingModalFeedback'

const ApplicantSharingModal = ({
  isModalOpen,
  onModalClose,
  applicationId,
}) => {
  const [selectedJobListing, setSelectedJobListing] = useState(null)
  const [selectedTrackingLink, setSelectedTrackingLink] = useState(null)
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [selectedCompanyData, setSelectedCompanyData] = useState(null)
  const [trackingLinkData, setTrackingLinkData] = useState<
    (string | ComboboxItem | ComboboxItemGroup<string | ComboboxItem>)[]
  >([])
  const [newApplication, setNewApplication] = useState(null)
  const { currentUser } = useAuth()

  const [copyApplication, { loading: copyApplicationLoading }] = useMutation(
    COPY_APPLICATION,
    {
      onCompleted: (data) => {
        toast('Applicant shared successfully.', 'success')
        if (
          data.copyApplication?.newApplicationId &&
          data.copyApplication?.newApplicantId
        ) {
          setNewApplication(data.copyApplication)
        }
      },
      onError: (err) => {
        if (err.message === 'APPLICANT_ACTIVE_IN_DESTINATION_COMPANY') {
          toast(
            `Applicant is already active in the destination company`,
            'error'
          )
          return
        }
        toast('Something went wrong, please try again.', 'error')
      },
    }
  )

  const {
    data: { getMyCompanies: companies } = {},
    loading: companiesLoading,
  } = useQuery(GET_MY_COMPANIES, {
    onError: () => {
      toast('Something went wrong, please try again.', 'error')
    },
  })

  const { data: jobListingsResponse, loading: jobListingsLoading } = useQuery(
    GET_JOB_LISTINGS,
    {
      skip: !selectedCompany,
      variables: {
        filters: { companyId: selectedCompany },
        pageInfo: { offset: 0, limit: 20 },
      },
      onError: () => {
        toast(
          'Something went wrong retrieving job listings, please try again.',
          'error'
        )
      },
    }
  )

  const companiesOptions =
    companies
      ?.filter((company) => company.companyId !== currentUser.companyId)
      .map((company) => ({
        value: company.companyId,
        label: company.companyName,
      })) || []

  const jobListingOptions =
    jobListingsResponse?.jobListings.items?.map((jl) => ({
      value: jl.jobListingId,
      label: jl.title,
    })) || []

  useEffect(() => {
    const jobListing = jobListingsResponse?.jobListings?.items.find(
      (jl) => jl.jobListingId === selectedJobListing
    )
    const trackingLinks = jobListing?.trackingLinks.map((link) => ({
      value: link.trackingLinkId,
      label: `${formatSnakeValue(link.linkType)}${
        link?.linkName ? `: ${link.linkName}` : ``
      }`,
    }))

    setTrackingLinkData(trackingLinks)
  }, [selectedJobListing, jobListingsResponse])

  useEffect(() => {
    if (selectedCompany) {
      setSelectedCompanyData(
        companies?.find((company) => company.companyId === selectedCompany)
      )
    }
  }, [selectedCompany, companies])

  const handleSubmit = useCallback(() => {
    copyApplication({
      variables: {
        input: {
          companyId: selectedCompany,
          jobListingId: selectedJobListing,
          trackingLinkId: selectedTrackingLink,
          applicationId,
        },
      },
    })
  }, [
    copyApplication,
    applicationId,
    selectedCompany,
    selectedJobListing,
    selectedTrackingLink,
  ])

  const [updateCurrentEmployeeId] = useMutation(UPDATE_CURRENT_EMPLOYEE_ID, {
    onCompleted: (data) => {
      navigate(
        routes.applicantDetails({
          id: newApplication.newApplicantId,
          tab: 'application',
        })
      )
      window.location.reload()
    },
    onError: () => {
      toast('Unable to switch companies. Please try again.', 'error')
    },
  })

  const updateCompanyAndRedirect = useCallback(async () => {
    await updateCurrentEmployeeId({
      variables: {
        input: {
          currentEmployeeId: selectedCompanyData?.employeeId,
        },
      },
    })
  }, [updateCurrentEmployeeId, selectedCompanyData?.employeeId])

  const isSaveDisabled =
    !selectedJobListing || !selectedTrackingLink || !selectedCompany

  return (
    <Modal
      opened={isModalOpen}
      onClose={onModalClose}
      classNames={{ title: 'text-lg font-bold' }}
      title="Share Applicant"
      centered
      size="45%"
    >
      {!newApplication && (
        <div className="flex flex-col gap-4">
          <div className="font-medium text-doubleNickel-gray-700">
            {`Please choose where you'd like to copy this applicant.`}
          </div>

          <div className="grid grid-cols-1 gap-4 pb-4">
            <Select
              label="Company"
              placeholder="Select a company"
              value={selectedCompany}
              onChange={(value) => {
                setSelectedTrackingLink(null)
                setSelectedJobListing(null)
                setSelectedCompany(value)
              }}
              rightSection={companiesLoading ? <Loader size="xs" /> : null}
              data={companiesOptions}
              withAsterisk
            />
            <Select
              key={jobListingOptions}
              label="Job Listing"
              placeholder="Select a job listing"
              defaultDropdownOpened={jobListingOptions?.length > 0}
              withAsterisk
              disabled={!selectedCompany}
              value={selectedJobListing}
              data={jobListingOptions}
              rightSection={jobListingsLoading ? <Loader size="xs" /> : null}
              onChange={(value) => {
                setSelectedTrackingLink(null)
                setSelectedJobListing(value)
              }}
            />
            <Select
              key={selectedJobListing}
              label="Tracking Link"
              placeholder="Select a tracking link"
              withAsterisk
              defaultDropdownOpened={selectedJobListing}
              disabled={!selectedJobListing}
              data={trackingLinkData}
              value={selectedTrackingLink}
              onChange={(value) => setSelectedTrackingLink(value)}
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <Divider className="col-span-2" />
            <Button text="Cancel" variant="outline" onClick={onModalClose} />
            <Tooltip
              label="Please select a company, job listing, and tracking link"
              disabled={!isSaveDisabled}
            >
              <Button
                text="Share"
                loading={copyApplicationLoading}
                onClick={handleSubmit}
                disabled={isSaveDisabled}
              />
            </Tooltip>
          </div>
        </div>
      )}
      {newApplication && (
        <ApplicantSharingModalFeedback
          title={'Applicant shared successfully'}
          subTitle={`This action will switch you to your ${selectedCompanyData.companyName} account`}
          onNavigateToApplicant={updateCompanyAndRedirect}
        />
      )}
    </Modal>
  )
}

export default ApplicantSharingModal
