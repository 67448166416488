import { useCallback } from 'react'

import { useLazyQuery } from '@apollo/client'
import { ActionIcon, Menu, Spoiler } from '@mantine/core'
import dayjs from 'dayjs'
import { ActivityLog } from 'types/graphql'

import StyledBadge from 'src/components/DataDisplay/StyledBadge/StyledBadge'
import { toast } from 'src/components/Overlays/Toast/Toast'
import { CallStatus } from 'src/context/PhoneDeviceContext'
import {
  DOWNLOAD_RECORDING_URL,
  DOWNLOAD_TRANSCRIPT_URL,
} from 'src/graphql/activityLogs.graphql'
import IconInboundCall from 'src/icons/IconInboundCall'
import IconMissedCall from 'src/icons/IconMissedCall'
import IconOutboundCall from 'src/icons/IconOutboundCall'
import IconThreeDots from 'src/icons/IconThreeDots'
import { buildFullName } from 'src/lib/formatters'
import { downloadFile } from 'src/utils/downloadFile'

const DropdownMenu = ({ activityLog }) => {
  const [downloadRecording, { called, data: downloadResponse }] = useLazyQuery(
    DOWNLOAD_RECORDING_URL,
    {
      onCompleted: () => {
        if (called && downloadResponse?.getRecordingDownloadUrl?.url) {
          const { url, fileName } = downloadResponse.getRecordingDownloadUrl
          downloadFile({ url, fileName })
        }
      },
      onError: () => {
        toast('Something went wrong, please try again.', 'error')
      },
    }
  )

  const [
    downloadTranscript,
    { called: calledTranscript, data: downloadTranscriptResponse },
  ] = useLazyQuery(DOWNLOAD_TRANSCRIPT_URL, {
    onCompleted: () => {
      if (
        calledTranscript &&
        downloadTranscriptResponse?.getTranscriptDownloadUrl?.url
      ) {
        const { url, fileName } =
          downloadTranscriptResponse.getTranscriptDownloadUrl
        downloadFile({ url, fileName })
      }
    },
    onError: () => {
      toast('Something went wrong, please try again.', 'error')
    },
  })

  const handleRecording = useCallback(() => {
    downloadRecording({
      variables: {
        activityLogId: activityLog.activityLogId,
      },
    })
  }, [activityLog, downloadRecording])

  const handleTranscript = useCallback(() => {
    downloadTranscript({
      variables: {
        activityLogId: activityLog.activityLogId,
      },
    })
  }, [activityLog, downloadTranscript])

  return (
    <Menu shadow="md" position="bottom-end">
      <Menu.Target>
        <ActionIcon variant="subtle" onClick={(e) => e.stopPropagation()}>
          <IconThreeDots className="h-4 fill-doubleNickel-white stroke-doubleNickel-gray-600" />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown className="w-40">
        <Menu.Item
          className="text-doubleNickel-gray-700"
          disabled={!activityLog.recordingSid}
          onClick={() => {
            handleRecording()
          }}
        >
          Download audio recording
        </Menu.Item>
        <Menu.Item
          className="text-doubleNickel-gray-700"
          disabled={!activityLog.transcriptFileUrl}
          onClick={() => {
            handleTranscript()
          }}
        >
          Download transcript
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

interface CallLogCardProps {
  activityLog: ActivityLog
}

const CallLog = ({ activityLog }: CallLogCardProps) => {
  const isOutboundCall = activityLog.callDirection === 'OUTBOUND'
  const isInboundCall = activityLog.callDirection === 'INBOUND'
  const label = isOutboundCall ? 'Outgoing Call' : 'Incoming Call'
  const isMissedCall =
    activityLog.callStatus === CallStatus.NO_ANSWER ||
    activityLog.callStatus === CallStatus.BUSY ||
    activityLog.callStatus === CallStatus.LEFT_VOICEMAIL
  const isLeftVoiceMail = activityLog.callStatus === CallStatus.LEFT_VOICEMAIL
  const callDurationInSeconds = dayjs(activityLog.endCallDate).diff(
    dayjs(activityLog.startCallDate),
    'seconds'
  )
  const callInMinutes = Math.floor(callDurationInSeconds / 60)
  const remindingSeconds = callDurationInSeconds % 60
  const isManualRegistered = activityLog.callStatus === null
  const voiceMailTitle = activityLog.voiceMailTemplate?.title

  return (
    <div
      className={`border-bg-doubleNickel-gray-600 flex w-full flex-col gap-2 border-b-[1px] px-2 py-4`}
    >
      <div className="flex flex-row items-center gap-2">
        <div className="flex flex-row items-center gap-2 text-sm font-semibold text-doubleNickel-gray-700">
          {label}
          {isOutboundCall && !isMissedCall && (
            <IconOutboundCall
              className="h-4 w-4"
              stroke="#079455"
              fill="#FFF"
            />
          )}
          {isInboundCall && !isMissedCall && (
            <IconInboundCall className="h-4 w-4" />
          )}
          {isMissedCall && <IconMissedCall className="h-4 w-4" />}
        </div>
        {isMissedCall && (
          <StyledBadge textTransform="capitalize" color="red">
            Missed
          </StyledBadge>
        )}
        {isManualRegistered && (
          <StyledBadge textTransform="capitalize">
            Manually registered
          </StyledBadge>
        )}
        {dayjs(activityLog.activityDate).isValid() && (
          <div className="ml-auto text-sm text-doubleNickel-gray-500">
            {dayjs(activityLog.activityDate).format('MM/DD/YYYY hh:mm A')}
          </div>
        )}
        <DropdownMenu activityLog={activityLog} />
      </div>
      <div
        className={`flex w-full flex-row justify-between text-sm font-medium text-doubleNickel-gray-700`}
      >
        <div className="flex flex-row">
          {!voiceMailTitle && !isMissedCall && !isNaN(callInMinutes) && (
            <p>
              {callInMinutes} minutes, {remindingSeconds} seconds
            </p>
          )}
          {isLeftVoiceMail && (
            <>
              <p>Left voicemail</p>
              {voiceMailTitle && <p>: {voiceMailTitle}</p>}
            </>
          )}
        </div>
        <div>
          <div className="ml-auto mr-9 text-sm text-doubleNickel-gray-500">
            {buildFullName(activityLog.employee)}
          </div>
        </div>
      </div>
      {activityLog?.transcriptSummary &&
        activityLog?.transcriptSummary !== 'NO_ANSWER' && (
          <Spoiler
            maxHeight={30}
            showLabel="Show more"
            hideLabel="Hide"
            classNames={{
              root: 'text-xs text-doubleNickel-gray-600',
              control: 'text-sm font-semibold',
            }}
          >
            {activityLog.transcriptSummary}
          </Spoiler>
        )}
    </div>
  )
}

export default CallLog
