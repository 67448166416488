import { CountryConfig, findIssuingCountry } from 'src/lib/country.utils'

export function getJobInformation(application) {
  let values = []
  if (application?.applicant) {
    values = [
      {
        jobListingId:
          application.applicant.jobListing?.jobListingId ?? undefined,
        linkType: application.trackingLink?.linkType ?? undefined,
        linkName: application.trackingLink?.linkName ?? undefined,
        trackingLinkId: application.trackingLink?.trackingLinkId ?? undefined,
        applicationDate: application?.applicant?.applicationDate
          ? new Date(application.applicant.applicationDate)
          : undefined,
        availableForWork: application?.availableForWork
          ? new Date(application.availableForWork)
          : undefined,
      },
    ]
  }

  return {
    values,
  }
}

export function getLicenseInformation(application) {
  let values = []
  if (application) {
    values = [
      {
        licenseNumber: application.license?.licenseNumber ?? undefined,
        state: application.license?.state ?? undefined,
        country: application.license?.country || 'US',
        licenseClass: application.license?.licenseClass ?? undefined,
        endorsements: application.license?.endorsements ?? undefined,
        expirationDate: application.license?.expirationDate
          ? new Date(application.license.expirationDate)
          : undefined,
        medicalExamExpirationDate: application.license
          ?.medicalExamExpirationDate
          ? new Date(application.license.medicalExamExpirationDate)
          : undefined,
        isLicenseSuspended:
          application.license?.isLicenseSuspended ?? undefined,
      },
    ]
  }

  return {
    values,
  }
}

export function getPersonalInformation(application) {
  // Default to US if no issuing country is found for backward compatibility
  const existingIssuingCountry = application?.issuingCountry
    ? findIssuingCountry(application.issuingCountry)
    : undefined
  const issuingCountryConfig = existingIssuingCountry || CountryConfig.US

  let values = []
  if (application) {
    values = [
      {
        firstName: application?.firstName ?? undefined,
        middleName: application?.middleName ?? undefined,
        lastName: application?.lastName ?? undefined,
        dob: application?.dob ? new Date(application.dob) : undefined,
        ssn: application?.ssn ?? undefined,
        issuingCountry: issuingCountryConfig?.code ?? undefined,
        email: application?.email ?? undefined,
        phone: application?.phone ?? undefined,
      },
    ]
  }

  return {
    values,
  }
}

export function getAccidentInformation(application) {
  let values = []
  if (application) {
    values =
      application.accidents.length > 0
        ? application.accidents.map((accident) => ({
            accidentId: accident?.accidentId ?? undefined,
            city: accident?.city ?? undefined,
            state: accident?.state ?? undefined,
            country: accident?.country || 'USA',
            description: accident?.description ?? undefined,
            isPreventable: accident?.isPreventable ?? undefined,
            isCommercialMotorVehicleInvolved:
              accident?.isCommercialMotorVehicleInvolved ?? undefined,
            accidentDate: accident?.accidentDate
              ? new Date(accident.accidentDate)
              : undefined,
          }))
        : []
  }

  return {
    values,
  }
}

export function getTwicInformation(application) {
  let values = []
  if (application) {
    values = [
      {
        cardNumber: application.twicCard?.cardNumber ?? undefined,
        expirationDate: application.twicCard?.expirationDate
          ? new Date(application.twicCard.expirationDate)
          : undefined,
      },
    ]
  }

  return {
    values,
  }
}

export function getOwnerOperatorEquipment(application) {
  let values = []
  if (application?.equipment) {
    values =
      application.equipment.length > 0
        ? application.equipment.map((equipment) => ({
            type: equipment?.type ?? undefined,
            year: equipment.year ?? undefined,
            make: equipment.make ?? undefined,
            model: equipment.model ?? undefined,
            vin: equipment.vin ?? undefined,
            color: equipment.color ?? undefined,
            weight: equipment.weight ?? undefined,
            mileage: equipment.mileage ?? undefined,
            fifthWheelHeightInches:
              equipment.fifthWheelHeightInches ?? undefined,
          }))
        : []
  }

  return {
    values,
  }
}

export function getAddressInformation(application) {
  let values = []
  if (application?.addresses) {
    values =
      application.addresses.length > 0
        ? application.addresses.map((address) => ({
            addressId: address?.addressId ?? undefined,
            city: address?.city ?? undefined,
            country: address?.country ?? undefined,
            state: address?.state ?? undefined,
            street: address?.street ?? undefined,
            zipCode: address?.zipCode ?? undefined,
            startDate: address?.startDate ?? undefined,
            endDate: address?.endDate ?? undefined,
          }))
        : []
  }

  return {
    values,
  }
}
