import { Text, Timeline } from '@mantine/core'
import dayjs from 'dayjs'

import StyledBadge from 'src/components/DataDisplay/StyledBadge/StyledBadge'
import AttachmentsMenu from 'src/components/Overlays/AttachmentsMenu/AttachmentsMenu'
import { CallStatus } from 'src/context/PhoneDeviceContext'
import { ActivityLogType } from 'src/graphql/types/activityLogs'
import IconInboundCall from 'src/icons/IconInboundCall'
import IconMissedCall from 'src/icons/IconMissedCall'
import IconOutboundCall from 'src/icons/IconOutboundCall'
import { buildFullName } from 'src/lib/formatters'

import { mapTypeToColor } from './mapTypeToColor'
import { useContext } from 'react'
import { RecruitersContext } from 'src/context/RecruitersContext'
import { replaceRecruitersIdWithName } from 'src/lib/recruiters.utils'

const CallTitle = ({ activityLog }) => {
  const isOutboundCall = activityLog.callDirection === 'OUTBOUND'
  const isInboundCall = activityLog.callDirection === 'INBOUND'
  const isManualRegistered = activityLog.callStatus === null

  const isMissedCall =
    activityLog.callStatus === CallStatus.NO_ANSWER ||
    activityLog.callStatus === CallStatus.BUSY ||
    activityLog.callStatus === CallStatus.LEFT_VOICEMAIL
  let label = isOutboundCall ? 'Outgoing call' : 'Inbound call'
  if (isMissedCall) {
    label = `Missed ${isInboundCall ? 'inbound' : 'outgoing'} call`
  }
  return (
    <div className="flex flex-col gap-1">
      <div className="flex gap-2">
        <div
          className={
            isMissedCall
              ? 'text-doubleNickel-error-700'
              : mapTypeToColor(ActivityLogType.CALL)
          }
        >
          {label}
        </div>
        {isMissedCall && <IconMissedCall className="h-4 w-4" />}
        {isOutboundCall && !isMissedCall && (
          <IconOutboundCall className="h-4 w-4" stroke="#079455" fill="#FFF" />
        )}
        {isInboundCall && !isMissedCall && (
          <IconInboundCall className="h-4 w-4" stroke="#079455" fill="#FFF" />
        )}
        {isManualRegistered && (
          <StyledBadge textTransform="capitalize">
            Manually registered
          </StyledBadge>
        )}
      </div>
      <div className="text-xs text-doubleNickel-gray-500">
        {dayjs(activityLog.activityDate).isValid() ? (
          <>{dayjs(activityLog.activityDate).format('MM/DD/YYYY hh:mm A')}</>
        ) : (
          <>{dayjs(activityLog.createdAt).format('MM/DD/YYYY hh:mm A')}</>
        )}
      </div>
    </div>
  )
}

const CallDescription = ({ activityLog }) => {
  const isOutboundCall = activityLog.callDirection === 'OUTBOUND'
  const isLeftVoiceMail = activityLog.callStatus === CallStatus.LEFT_VOICEMAIL
  const callDurationInSeconds = dayjs(activityLog.endCallDate).diff(
    dayjs(activityLog.startCallDate),
    'seconds'
  )
  const callInMinutes = Math.floor(callDurationInSeconds / 60)
  const remindingSeconds = callDurationInSeconds % 60
  const voiceMailTitle = activityLog.voiceMailTemplate?.title

  const isMissedCall =
    activityLog.callStatus === CallStatus.NO_ANSWER ||
    activityLog.callStatus === CallStatus.BUSY ||
    activityLog.callStatus === CallStatus.LEFT_VOICEMAIL

  return (
    <Text className="text-sm text-doubleNickel-gray-900">
      <div>
        {!voiceMailTitle &&
          isOutboundCall &&
          !isMissedCall &&
          !isNaN(callInMinutes) && (
            <p>
              {callInMinutes} minutes, {remindingSeconds} seconds
            </p>
          )}
        {isLeftVoiceMail && voiceMailTitle && (
          <p>Voicemail: {voiceMailTitle}</p>
        )}
      </div>
      <div>
        <p>Recruiter: {buildFullName(activityLog.employee)}</p>
      </div>
    </Text>
  )
}

export const ActivityLogTimelineItem = ({ activityLog, applicantId }) => {
  const recruiters = useContext(RecruitersContext)
  const showContent =
    activityLog.type === ActivityLogType.NOTE ||
    activityLog.type === ActivityLogType.SMS ||
    activityLog.type === ActivityLogType.DOCUMENT ||
    activityLog.type === ActivityLogType.APPLICANT

  const mapTypeToBadgeColor = (type) => {
    switch (type) {
      case ActivityLogType.CALL:
        return 'green'
      case ActivityLogType.SMS:
        return 'blue'
      case ActivityLogType.NOTE:
        return 'orange'
      default:
        return 'gray'
    }
  }

  return (
    <Timeline.Item
      key={activityLog.activityLogId}
      title={
        <div className="flex flex-row gap-1 text-sm text-doubleNickel-gray-900">
          {(showContent || activityLog.type === ActivityLogType.APPLICANT) && (
            <div className="flex flex-col gap-1 font-normal">
              <div className="font-medium">{activityLog.title}</div>
              <div className="text-xs text-doubleNickel-gray-500">
                {dayjs(activityLog.createdAt).format('MM/DD/YYYY hh:mm A')}
              </div>
            </div>
          )}
          {activityLog.type === ActivityLogType.CALL && (
            <CallTitle activityLog={activityLog} />
          )}
        </div>
      }
    >
      <div className="flex flex-row items-center justify-between">
        <>
          <Text className="text-sm text-doubleNickel-gray-900">
            {showContent && (
              <p>
                {replaceRecruitersIdWithName(activityLog.content, recruiters)}
              </p>
            )}

            {activityLog.type === ActivityLogType.CALL && (
              <CallDescription activityLog={activityLog} />
            )}
          </Text>
          {activityLog?.documents && activityLog.documents.length > 0 && (
            <div className="mt-2">
              <AttachmentsMenu
                attachments={activityLog.documents}
                applicantId={applicantId}
              />
            </div>
          )}
        </>
        <StyledBadge
          textTransform="capitalize"
          color={mapTypeToBadgeColor(activityLog.type)}
        >
          {activityLog.type}
        </StyledBadge>
      </div>
    </Timeline.Item>
  )
}
