import { useState } from 'react'

import { ActionIcon } from '@mantine/core'

import DateInput from 'src/components/Inputs/DateInput/DateInput'
import TextInput from '../TextInput/TextInput'
import Button from 'src/components/Buttons/Button/Button'
import IconEdit from 'src/icons/IconEdit'

const TwicInformationCard = ({
  values: appValues = [],
  handleUpdateApplication,
}) => {
  const [values, setValues] = useState({ ...appValues[0] })
  const [editing, setEditing] = useState(false)

  const handleCancel = () => {
    setValues({ ...appValues[0] })
    setEditing(false)
  }

  const handleSave = () => {
    let updates = {}
    updates = { twicCard: { ...values } }
    handleUpdateApplication(updates).then((res) => {
      if (!res.errors) {
        setEditing(false)
      }
    })
  }

  return (
    <div
      className={`flex h-full w-full flex-col gap-4 rounded-lg border border-doubleNickel-gray-200 p-4`}
    >
      <div className=" text-md flex flex-row items-center justify-between font-semibold text-doubleNickel-black">
        TWIC
        {!editing && (
          <ActionIcon
            className="h-8 w-8 border border-doubleNickel-gray-200 bg-doubleNickel-white shadow-sm hover:bg-doubleNickel-gray-200"
            onClick={() => setEditing(true)}
          >
            <IconEdit className="h-4 w-4 fill-none stroke-doubleNickel-gray-500" />
          </ActionIcon>
        )}
      </div>

      <div className="flex flex-col gap-2">
        <TextInput
          label="TWIC Number"
          placeholder="No TWIC Number Found"
          editing={editing}
          value={values.cardNumber}
          handleChange={(value) => setValues({ ...values, cardNumber: value })}
        />
        <DateInput
          label="TWIC Expiration Date"
          editing={editing}
          value={values.expirationDate}
          onChange={(value) => {
            setValues({ ...values, expirationDate: value })
          }}
        />
      </div>
      {editing && (
        <div className="flex flex-row gap-4">
          <Button
            text="Cancel"
            variant="outline"
            className="ml-auto"
            onClick={handleCancel}
          />
          <Button text="Save" onClick={handleSave} />
        </div>
      )}
    </div>
  )
}

export default TwicInformationCard
