export const mapTypeToColor = (type: string) => {
  switch (type) {
    case 'NOTE':
      return 'text-doubleNickel-warning-500'
    case 'SMS':
      return 'text-doubleNickel-brand-500'
    case 'CALL':
      return 'text-doubleNickel-success-500'
    case 'EMAIL':
      return 'text-doubleNickel-warning-600'
    case 'DOCUMENT':
      return 'text-doubleNickel-blue-500'
    default:
      return 'text-doubleNickel-gray-700'
  }
}
