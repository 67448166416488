import { useCallback, useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { LoadingOverlay, Box, Menu } from '@mantine/core'
import { Button as MantineButton } from '@mantine/core'
import { RingProgress, Text } from '@mantine/core'
import { Applicant, Step, StepType } from 'types/graphql'

import { navigate, routes, useParams } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import Button from 'src/components/Buttons/Button/Button'
import PaginationArea from 'src/components/Navigation/PaginationArea/PaginationArea'
import DeleteModal from 'src/components/Overlays/DeleteModal/DeleteModal'
import DocumentPreview from 'src/components/Overlays/DocumentPreview/DocumentPreview'
import { toast } from 'src/components/Overlays/Toast/Toast'
import {
  GET_DOCUMENTS,
  DELETE_DOCUMENT,
  GET_DOCUMENT_DOWNLOAD_URL,
  SET_DOCUMENT_RENEWED,
} from 'src/graphql/documents.graphql'
import { GET_STEPS_GROUP_BY_TYPE } from 'src/graphql/steps.graphql'
import { DQF_STEP_TYPES, StepStatus } from 'src/graphql/types/steps'
import { stepTypeToDocumentCategoryMapping } from 'src/graphql/types/steps'
import { usePage } from 'src/hooks/usePage/usePage'
import IconChevronDown from 'src/icons/IconChevronDown'
import IconDocumentSearch from 'src/icons/IconDocumentSearch'
import IconFileCabinet from 'src/icons/IconFileCabinet'
import { downloadFile } from 'src/utils/downloadFile'

import DocumentsTable from './components/DocumentsTable/DocumentsTable'
import DQFContent from './components/DQFContent/DQFContent'
import EditDocumentModal from './components/EditDocumentModal/EditDocumentModal'
import SendDocumentsToSignModal from './components/SendDocumentsToSignModal/SendDocumentsToSignModal'
import UploadDocumentModal from './components/UploadDocumentModal/UploadDocumentModal'

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}

const DocumentsTab = ({
  applicantId,
  dqf,
  applicantInfo,
}: {
  applicantId: string
  dqf: StepType
  applicantInfo: Applicant
}) => {
  const params = useParams()
  const [isUploadDocumentModalOpen, setIsDocumentUploadModalOpen] =
    useState(false)
  const [documentToDelete, setDocumentToDelete] = useState(null)
  const [documentToEdit, setDocumentToEdit] = useState(null)
  const [page, setPage] = usePage()
  const [documentForPreview, setDocumentForPreview] = useState(null)
  const [selectedSubTab, setSelectedSubTab] = useState(params?.subtab || 'DQF')
  const [DQFCompletion, setDQFCompletion] = useState(0)
  const [selectedStep, setSelectedStep] = useState(null)
  const [openSendDocumentsToSignModal, setOpenSendDocumentsToSignModal] =
    useState(false)

  const LIMIT = 20

  useEffect(() => {
    setSelectedSubTab(params?.subtab || 'DQF')
  }, [params.subtab])

  //TODO: Refactor to refresh only when necessary, not when selecting tabs
  const refetchQueries = [
    {
      query: GET_DOCUMENTS,
      variables: {
        applicantId,
        pageInfo: { offset: (page - 1) * LIMIT, limit: LIMIT },
        ...(selectedStep &&
          selectedSubTab === 'DQF' && {
            filters: {
              categories: [
                stepTypeToDocumentCategoryMapping[selectedStep.type],
              ],
            },
          }),
      },
    },
    {
      query: GET_STEPS_GROUP_BY_TYPE,
      variables: {
        applicantId,
        pageInfo: { offset: (page - 1) * LIMIT, limit: LIMIT },
        stepTypes: DQF_STEP_TYPES,
      },
    },
  ]

  const [
    loadDocuments,
    { data: documentsResponse, loading: loadingDocuments },
  ] = useLazyQuery(GET_DOCUMENTS, {
    onError,
  })

  const [loadSteps, { data: stepsResponse, loading: loadingDFQ }] =
    useLazyQuery(GET_STEPS_GROUP_BY_TYPE, {
      variables: {
        applicantId,
        pageInfo: { offset: (page - 1) * LIMIT, limit: LIMIT },
        stepTypes: DQF_STEP_TYPES,
      },
      onError,
    })

  const firstStep =
    stepsResponse?.stepsGroupByType?.items.length > 0
      ? dqf
        ? stepsResponse.stepsGroupByType.items.find((item) => item.type === dqf)
        : stepsResponse.stepsGroupByType.items[0]
      : null

  useEffect(() => {
    if (!selectedStep) setSelectedStep(firstStep)
    if (firstStep && !selectedStep) {
      handleStepChange(firstStep)
    }
  }, [firstStep])

  useEffect(() => {
    if (selectedSubTab === 'documents') {
      loadDocuments({
        variables: {
          applicantId,
          pageInfo: { offset: (page - 1) * LIMIT, limit: LIMIT },
        },
      })
    }
  }, [params, loadDocuments, applicantId, page])

  useEffect(() => {
    const newStep = stepsResponse?.stepsGroupByType?.items.find(
      (step) => step.type === dqf
    )
    if (newStep) {
      setSelectedStep(newStep)
      handleStepChange(newStep)
    }
  }, [dqf])

  useEffect(() => {
    if (selectedSubTab === 'DQF') {
      loadSteps()
      if (!selectedStep) {
        firstStep && setSelectedStep(firstStep)
      }
    } else {
      setSelectedStep(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubTab])

  const [deleteDocument] = useMutation(DELETE_DOCUMENT, {
    refetchQueries,
    onCompleted: () => {
      setDocumentToDelete(null)
      if (documentsResponse?.documents.totalCount % LIMIT === 1 && page > 1) {
        setPage(page - 1)
      }
      toast('Document deleted successfully', 'success')
    },
    onError,
  })

  const [setDocumentRenewed] = useMutation(SET_DOCUMENT_RENEWED, {
    refetchQueries,
    onCompleted: (res) => {
      toast(
        `Document marked as ${
          res.setDocumentRenewed.isRenewed ? 'renewed' : 'not renewed'
        }`,
        'success'
      )
    },
    onError,
  })

  const [downloadDocument, { called, data: downloadResponse }] = useLazyQuery(
    GET_DOCUMENT_DOWNLOAD_URL,
    {
      onCompleted: () => {
        if (called && downloadResponse?.documentDownloadUrl?.url) {
          const { url, fileName } = downloadResponse.documentDownloadUrl
          downloadFile({ url, fileName })
        }
      },
      onError,
    }
  )

  const handleDelete = (documentId) => {
    deleteDocument({
      variables: {
        id: documentId,
        applicantId,
      },
    })
  }

  const handleDeleteConfirmation = (documentId) => {
    setDocumentToDelete(documentId)
  }

  const handleEditDocument = (document) => {
    setDocumentToEdit(document)
  }

  const handleDownload = (documentId) => {
    downloadDocument({
      variables: {
        id: documentId,
        applicantId,
      },
    })
  }

  const handlePreview = (document) => {
    setDocumentForPreview(document)
  }

  const handleUpload = () => {
    setIsDocumentUploadModalOpen(true)
  }

  const handleUploadComplete = () => {
    loadSteps()

    if (selectedStep) {
      handleStepChange(selectedStep)
    }
  }

  const handleStepChange = (step: Step) => {
    const documentCategory =
      stepTypeToDocumentCategoryMapping[step.type] || null

    loadDocuments({
      variables: {
        applicantId,
        pageInfo: { offset: (page - 1) * LIMIT, limit: LIMIT },
        ...(selectedSubTab === 'DQF' && {
          filters: {
            ...(documentCategory && {
              categories: [documentCategory],
            }),
          },
        }),
      },
    })
  }

  const handleDocumentRenewed = (documentId, isRenewed) => {
    setDocumentRenewed({
      variables: {
        documentId,
        isRenewed,
        applicantId,
      },
    })
  }

  const onOpenSendDocumentSigningModal = useCallback(() => {
    setOpenSendDocumentsToSignModal(true)
  }, [])

  useEffect(() => {
    if (stepsResponse?.stepsGroupByType) {
      const { items } = stepsResponse.stepsGroupByType
      const totalSteps = items.length
      const completedSteps = items.filter(
        (step) => step.status === StepStatus.COMPLETED
      ).length
      const completion = (completedSteps / totalSteps) * 100
      setDQFCompletion(completion)
    }
  }, [stepsResponse])

  const DocumentsContent = () => (
    <>
      <Box className="flex h-full flex-col overflow-auto " pos={'relative'}>
        <DocumentsTable
          documents={documentsResponse?.documents.items}
          loading={loadingDocuments}
          onEdit={handleEditDocument}
          onDownload={handleDownload}
          onPreview={handlePreview}
          onDelete={handleDeleteConfirmation}
          onRenewed={handleDocumentRenewed}
          refetchQueries={refetchQueries}
        />
        <LoadingOverlay
          visible={loadingDocuments}
          overlayProps={{ opacity: 0 }}
          zIndex={1000}
        />
      </Box>
      <PaginationArea
        page={page}
        totalPages={Math.ceil(documentsResponse?.documents.totalCount / LIMIT)}
        onPageChange={setPage}
      />
    </>
  )

  const DocumentsHeader = ({
    title = '',
    totalCount = 0,
    showDQFCompletion = false,
  }) => (
    <div className="relative flex flex-row items-center justify-between gap-4">
      <div className="flex flex-row items-center gap-2">
        <div className="flex flex-col">
          <div className="text-lg font-semibold text-doubleNickel-black">
            {title}
          </div>
          <div className="text-sm text-doubleNickel-gray-600">
            <span>{totalCount}</span>
            <span>{selectedSubTab === 'DQF' ? ' steps' : ' document(s)'}</span>
          </div>
        </div>
        {showDQFCompletion && DQFCompletion !== null && (
          <div className="flex flex-row items-center gap-1">
            <RingProgress
              sections={[{ value: Number(DQFCompletion), color: 'blue' }]}
              size={55}
              roundCaps
              thickness={5}
              label={
                <Text className="flex items-center justify-center text-xs font-medium text-doubleNickel-gray-600">
                  {DQFCompletion.toFixed(0)}%
                </Text>
              }
            />
            <Text className="text-xs font-medium text-doubleNickel-gray-600">
              DQF Completion
            </Text>
          </div>
        )}
      </div>
      <div className="flex flex-row gap-2">
        <MantineButton.Group>
          <Button
            text="DQF"
            className="rounded-lg"
            variant={selectedSubTab === 'DQF' ? 'light' : 'outline'}
            onClick={() => {
              navigate(
                routes.applicantDetails({
                  id: applicantId,
                  tab: 'documents',
                  subtab: 'DQF',
                })
              )
              loadDocuments({
                variables: {
                  applicantId,
                  pageInfo: { offset: (page - 1) * LIMIT, limit: LIMIT },
                  filters: {
                    ...(selectedStep && {
                      categories: [
                        stepTypeToDocumentCategoryMapping[selectedStep.type],
                      ],
                    }),
                  },
                },
              })
            }}
            lefticon={<IconDocumentSearch />}
          />
          <Button
            text="Documents"
            className="rounded-lg"
            variant={selectedSubTab === 'documents' ? 'light' : 'outline'}
            onClick={() => {
              navigate(
                routes.applicantDetails({
                  id: applicantId,
                  tab: 'documents',
                  subtab: 'documents',
                })
              )
              loadDocuments({
                variables: {
                  applicantId,
                  pageInfo: { offset: (page - 1) * LIMIT, limit: LIMIT },
                },
              })
            }}
            lefticon={<IconFileCabinet />}
          />
        </MantineButton.Group>

        <Menu shadow="md" position="bottom-end">
          <Menu.Target>
            <Box className="font-lg flex flex-row items-center justify-between font-bold text-doubleNickel-gray-700">
              <Button
                text="Actions"
                righticon={
                  <IconChevronDown className="h-3 fill-none stroke-doubleNickel-gray-600" />
                }
              />
            </Box>
          </Menu.Target>
          <Menu.Dropdown className="w-40">
            {selectedSubTab === 'documents' && (
              <Menu.Item
                className={` text-doubleNickel-gray-700`}
                onClick={() => setIsDocumentUploadModalOpen(true)}
              >
                Upload
              </Menu.Item>
            )}
            <Menu.Item
              className="text-doubleNickel-gray-700"
              onClick={() => onOpenSendDocumentSigningModal()}
            >
              Send Documents for Signing
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </div>
    </div>
  )

  return (
    <div className="flex h-[90%] flex-col gap-4 overflow-auto">
      {documentForPreview ? (
        <DocumentPreview
          documentId={documentForPreview.documentId}
          applicantId={applicantId}
          onBack={() => setDocumentForPreview(null)}
        />
      ) : (
        <>
          <DocumentsHeader
            title={
              selectedSubTab === 'DQF'
                ? 'Driver Qualification Files (DQF)'
                : 'Documents'
            }
            totalCount={
              selectedSubTab === 'DQF'
                ? stepsResponse?.stepsGroupByType.totalCount
                : documentsResponse?.documents.totalCount
            }
            showDQFCompletion={selectedSubTab === 'DQF'}
          />
          {selectedSubTab === 'documents' && <DocumentsContent />}
          {selectedSubTab === 'DQF' && stepsResponse && selectedStep && (
            <DQFContent
              steps={stepsResponse?.stepsGroupByType}
              documentsResponse={documentsResponse?.documents.items}
              handleUpload={handleUpload}
              onDelete={handleDeleteConfirmation}
              onEdit={handleEditDocument}
              onPreview={handlePreview}
              onDownload={handleDownload}
              onStepChange={handleStepChange}
              onRenewed={handleDocumentRenewed}
              refetchQueries={refetchQueries}
              onError={onError}
              applicantId={applicantId}
              selected={selectedStep}
              setSelected={setSelectedStep}
            />
          )}
        </>
      )}
      {isUploadDocumentModalOpen && (
        <UploadDocumentModal
          step={selectedStep}
          applicantId={applicantId}
          isOpen={isUploadDocumentModalOpen}
          onRender={refetchQueries}
          onClose={() => setIsDocumentUploadModalOpen(false)}
          onUpdateComplete={handleUploadComplete}
          voe={null}
          disableCategorySelection={selectedSubTab === 'DQF'}
        />
      )}
      {documentToEdit && (
        <EditDocumentModal
          document={documentToEdit}
          isOpen={Boolean(documentToEdit)}
          onRender={refetchQueries}
          onClose={() => {
            setDocumentToEdit(null)
          }}
        />
      )}
      {documentToDelete && (
        <DeleteModal
          title="Are you sure you want to delete this document?"
          opened={Boolean(documentToDelete)}
          close={() => setDocumentToDelete(null)}
          onDelete={() => handleDelete(documentToDelete)}
        />
      )}
      {openSendDocumentsToSignModal && (
        <SendDocumentsToSignModal
          email={applicantInfo.email}
          isOpen={openSendDocumentsToSignModal}
          onClose={() => setOpenSendDocumentsToSignModal(false)}
          applicantId={applicantId}
        />
      )}
    </div>
  )
}

export default DocumentsTab
