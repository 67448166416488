import { Box, Menu, ActionIcon, Tooltip } from '@mantine/core'
import dayjs from 'dayjs'

import StyledBadge from 'src/components/DataDisplay/StyledBadge/StyledBadge'
import IconAlertTriangle from 'src/icons/IconAlertTriangle'
import IconThreeDots from 'src/icons/IconThreeDots'
import { formatDate, formatSnakeValue } from 'src/lib/formatters'

const colorByStatus = {
  NOT_STARTED: 'gray',
  IN_PROGRESS: 'blue',
  PENDING_REVIEW: 'orange',
  COMPLETED: 'green',
  EXPIRED: 'red',
}

const DQFDocument = ({
  document,
  step,
  onPreview,
  onEdit,
  onDownload,
  onDelete,
  onRenewed,
}) => {
  const formattedExpirationDate = dayjs(
    new Date(document.expirationDate)
  ).format('MM/DD/YYYY')

  // Expiry date calculation
  // const daysUntilDueDate = null
  let isNearExpiry = false
  let isExpired = false

  let tooltipLabel = ''
  let iconColorClass = ''
  let daysUntilDueDate = null

  if (document.expirationDate) {
    const today = dayjs().startOf('day')
    const expirationDate = dayjs(new Date(document.expirationDate)).endOf('day')
    daysUntilDueDate = expirationDate.diff(today, 'day')

    isNearExpiry = daysUntilDueDate <= 30 && daysUntilDueDate >= 0
    isExpired = daysUntilDueDate < 0

    if (isNearExpiry) {
      if (daysUntilDueDate === 0) {
        tooltipLabel = 'Expires today'
      } else if (daysUntilDueDate === 1) {
        tooltipLabel = 'Expires in 1 day'
      } else {
        tooltipLabel = `Expires in ${daysUntilDueDate} days`
      }
      iconColorClass = 'stroke-doubleNickel-warning-500' // Warning color
    } else if (isExpired) {
      tooltipLabel = 'Expired'
      iconColorClass = 'stroke-doubleNickel-error-500' // Error color
    }
  }

  return (
    <div
      className="flex flex-row items-center justify-between border-b"
      key={document.documentId}
    >
      <Box
        className={`flex cursor-pointer flex-row items-center justify-between gap-4 overflow-hidden px-2 py-3`}
      >
        <div className="flex flex-col overflow-hidden">
          <Box
            className="flex flex-row gap-2 truncate text-sm font-medium text-doubleNickel-brand-500 hover:underline"
            onClick={() => onPreview(document)}
          >
            {document.fileName}
            {!document.isRenewed &&
              document.expirationDate &&
              (isNearExpiry || isExpired) && (
                <Tooltip label={tooltipLabel} withArrow>
                  <div>
                    <IconAlertTriangle
                      className={`fill-none ${iconColorClass}`}
                    />
                  </div>
                </Tooltip>
              )}
          </Box>
          <div className="text-xs text-doubleNickel-gray-700">
            Uploaded {formatDate(document.createdAt)}
            {document.expirationDate &&
              (daysUntilDueDate < 0
                ? ` | Expired on ${formattedExpirationDate}`
                : ` | Expires on ${formattedExpirationDate}`)}
          </div>
        </div>
      </Box>
      <div className="flex flex-row items-center gap-2">
        <StyledBadge
          textTransform="capitalize"
          color={colorByStatus[document.userStatus]}
        >
          {formatSnakeValue(document.userStatus)}
        </StyledBadge>
        <Menu shadow="md" position="bottom-end">
          <Menu.Target>
            <ActionIcon variant="subtle" className="h-6 w-6">
              <IconThreeDots className="fill-doubleNickel-white stroke-doubleNickel-gray-600" />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown className="w-40">
            <Menu.Item
              className="text-doubleNickel-gray-700"
              onClick={() => onPreview(document)}
            >
              View
            </Menu.Item>
            <Menu.Item
              className="text-doubleNickel-gray-700"
              onClick={() => onEdit(document, step)}
            >
              Edit
            </Menu.Item>
            <Menu.Item
              className="text-doubleNickel-gray-700"
              onClick={() => onDownload(document.documentId)}
            >
              Download
            </Menu.Item>
            <Menu.Item
              className="text-doubleNickel-gray-700"
              onClick={() =>
                onRenewed(document.documentId, !document.isRenewed)
              }
            >
              Mark as {document.isRenewed ? 'not renewed' : 'renewed'}
            </Menu.Item>
            <Menu.Item
              disabled={Boolean(document.voe)}
              className="text-doubleNickel-gray-700"
              onClick={() => onDelete(document.documentId, step)}
            >
              Delete
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </div>
    </div>
  )
}

export default DQFDocument
