const IconFileCabinet = (props) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill={props.fill}
      stroke={props.stroke}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2855 5.66667V12.5C17.2855 13.9001 17.2855 14.6002 17.013 15.135C16.7733 15.6054 16.3909 15.9878 15.9205 16.2275C15.3857 16.5 14.6856 16.5 13.2855 16.5H7.11884C5.7187 16.5 5.01864 16.5 4.48386 16.2275C4.01345 15.9878 3.631 15.6054 3.39132 15.135C3.11884 14.6002 3.11884 13.9001 3.11884 12.5V5.66667M3.20217 1.5H17.2022C17.6689 1.5 17.9022 1.5 18.0805 1.59083C18.2373 1.67072 18.3648 1.79821 18.4447 1.95501C18.5355 2.13327 18.5355 2.36662 18.5355 2.83333V4.33333C18.5355 4.80004 18.5355 5.0334 18.4447 5.21166C18.3648 5.36846 18.2373 5.49594 18.0805 5.57584C17.9022 5.66667 17.6689 5.66667 17.2022 5.66667H3.20217C2.73546 5.66667 2.5021 5.66667 2.32384 5.57584C2.16704 5.49594 2.03956 5.36846 1.95966 5.21166C1.86884 5.0334 1.86884 4.80004 1.86884 4.33333V2.83333C1.86884 2.36662 1.86884 2.13327 1.95966 1.95501C2.03956 1.79821 2.16704 1.67072 2.32384 1.59083C2.5021 1.5 2.73546 1.5 3.20217 1.5ZM8.20217 8.58333H12.2022C12.6689 8.58333 12.9022 8.58333 13.0805 8.67416C13.2373 8.75406 13.3648 8.88154 13.4447 9.03834C13.5355 9.2166 13.5355 9.44996 13.5355 9.91667V10.5833C13.5355 11.05 13.5355 11.2834 13.4447 11.4617C13.3648 11.6185 13.2373 11.7459 13.0805 11.8258C12.9022 11.9167 12.6689 11.9167 12.2022 11.9167H8.20217C7.73546 11.9167 7.5021 11.9167 7.32384 11.8258C7.16704 11.7459 7.03956 11.6185 6.95966 11.4617C6.86884 11.2834 6.86884 11.05 6.86884 10.5833V9.91667C6.86884 9.44996 6.86884 9.2166 6.95966 9.03834C7.03956 8.88154 7.16704 8.75406 7.32384 8.67416C7.5021 8.58333 7.73546 8.58333 8.20217 8.58333Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconFileCabinet
