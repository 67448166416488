import { VerificationOfEmployment } from 'types/graphql'

import { formatInternationalPhoneNumber } from 'src/lib/phone.utils'
import StyledReadOnlyInput from 'src/components/Inputs/StyledReadOnlyInput/StyledReadOnlyInput'

const Column = ({ title, children, colspan = 'col-span-1' }) => (
  <div className={`${colspan}`}>
    <div className="font-semibold text-doubleNickel-gray-600">{title}</div>
    {children}
  </div>
)

const VoEApplicationTableInnerRow = ({
  voe,
}: {
  voe: VerificationOfEmployment
}) => {
  return (
    <div className="flex-1 p-6">
      <div className="mb-4 font-semibold text-doubleNickel-gray-600">
        Verification of Employment Details
      </div>
      <div className="grid grid-cols-2 gap-y-6">
        <StyledReadOnlyInput
          label="DoT Number"
          value={voe.dotNumber || 'n/a'}
        />
        <StyledReadOnlyInput
          label="Contact Name"
          value={voe.contactName || 'n/a'}
        />
        <StyledReadOnlyInput
          label="Phone Number"
          value={formatInternationalPhoneNumber(voe.phoneNumber) || 'n/a'}
        />
        <StyledReadOnlyInput
          label="Fax Number"
          value={formatInternationalPhoneNumber(voe.faxNumber) || 'n/a'}
        />
        <div className="col-span-2">
          <StyledReadOnlyInput
            label="Email address"
            value={voe.email || 'n/a'}
          />
        </div>
      </div>
    </div>
  )
}

export default VoEApplicationTableInnerRow
