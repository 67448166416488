const IconDocumentSearch = (props) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill={props.fill}
      stroke={props.stroke}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.8688 9.1665H4.86879M6.53546 12.4998H4.86879M11.5355 5.83317H4.86879M14.8688 8.74984V5.6665C14.8688 4.26637 14.8688 3.56631 14.5963 3.03153C14.3566 2.56112 13.9742 2.17867 13.5038 1.93899C12.969 1.6665 12.2689 1.6665 10.8688 1.6665H5.53546C4.13533 1.6665 3.43526 1.6665 2.90049 1.93899C2.43008 2.17867 2.04763 2.56112 1.80795 3.03153C1.53546 3.56631 1.53546 4.26637 1.53546 5.6665V14.3332C1.53546 15.7333 1.53546 16.4334 1.80795 16.9681C2.04763 17.4386 2.43008 17.821 2.90049 18.0607C3.43526 18.3332 4.13533 18.3332 5.53546 18.3332H7.78546M16.5355 18.3332L15.2855 17.0832M16.1188 14.9998C16.1188 16.6107 14.813 17.9165 13.2021 17.9165C11.5913 17.9165 10.2855 16.6107 10.2855 14.9998C10.2855 13.389 11.5913 12.0832 13.2021 12.0832C14.813 12.0832 16.1188 13.389 16.1188 14.9998Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconDocumentSearch
